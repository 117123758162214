
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import { pluralize } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import VisitConditionDetailsSectionHeader from '@/components/visit/conditions/VisitConditionDetailsSectionHeader.vue';
import LabsTable from '@/components/visit/labs/LabsTable.vue';
import { ToggleButtonName } from '@/models';
import { AddToClinicalSummaryInput, ClinicalSummaryItemType } from '@/models/visit/clinical-summary/ClinicalSummary.model';
import { LabVitalsResultsTable } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'LabsConditionDetailView',
    components: { AddToClinicalSummaryButton, ToggleButtons, LabsTable, VisitConditionDetailsSectionHeader },
    provide: {
        enableLabSelection: true,
    },
    props: {
        conditionName: {
            type: String,
            required: true,
        },
        supportingLabResults: { type: Object as PropType<LabVitalsResultsTable>, required: true },
    },
    data: () => ({
        ToggleButtonName,
        selectedButton: undefined as ToggleButtonName | undefined,
        disableAbnormalLabsToggle: false,
        abnormalLabsLength: 0,
        clinicalSummaryItems: {} as AddToClinicalSummaryInput,
    }),
    computed: {
        headerText(): string {
            const lengthText =
                this.selectedButton === ToggleButtonName.ABNORMAL_LABS
                    ? this.abnormalLabsLength
                    : this.supportingLabResults?.rows.length.toString();
            const typeText =
                this.selectedButton === ToggleButtonName.ABNORMAL_LABS
                    ? 'Abnormal ' + pluralize('Lab', this.abnormalLabsLength)
                    : pluralize('Lab', this.supportingLabResults?.rows.length ?? 0);
            return lengthText + ' ' + typeText + ' for ' + this.conditionName;
        },
    },
    created() {
        if (this.supportingLabResults.rows.length === 0) {
            this.$emit('noData', true);
        }
        this.selectedButton = ToggleButtonName.ABNORMAL_LABS;
    },
    methods: {
        setAbnormalLabsCount(count: number) {
            this.abnormalLabsLength = count;
            if (this.abnormalLabsLength === 0) {
                this.disableToggle(true);
            }
        },
        disableToggle(abnormalLabsNotPresent: boolean) {
            this.disableAbnormalLabsToggle = abnormalLabsNotPresent;
            this.selectedButton = ToggleButtonName.ALL_LABS;
        },
        async toggleView(selectedButton: ToggleButtonName): Promise<void> {
            this.selectedButton = selectedButton;
        },
        updateSelectedItems(ids: string[]) {
            this.clinicalSummaryItems = { type: ClinicalSummaryItemType.LAB_RESULT, ids: ids };
        },
    },
});
