var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"ml-4 my-4 py-4 px-6",class:{
        'v4-card': _vm.isModelV4Enabled,
        'v1-card': !_vm.isModelV4Enabled,
    },attrs:{"data-testid":"predicted-status-card","elevation":"1"}},[_c('v-row',{staticClass:"d-flex flex-row justify-space-between",class:{ 'pb-4': _vm.isModelV4Enabled }},[_c('div',{staticClass:"d-flex flex-column"},[_c('VerticalField',{scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v("Predicted Status")]},proxy:true}])}),_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.visit.predictedAdmitStatus)?_c('span',{staticClass:"neutral-900--text text-h5 pr-1",attrs:{"data-testid":"predicted-status"}},[_vm._v(" "+_vm._s(_vm.predictedAdmitStatus)+" ")]):_c('span',{staticClass:"neutral-900--text text-subtitle-2 pr-1 pb-14",attrs:{"data-testid":"no-predicted-status"}},[_vm._v(" Predicted Status Not Available ")]),(!_vm.isPredictedAdmitStatusBelowThreshold)?_c('ConfidenceIcon',{attrs:{"visitId":_vm.visit.id,"confidence":Number(_vm.visit.predictedAdmitStatusConfidence),"admitStatusPredictions":_vm.visit.currentAdmitStatusPrediction,"predictedStatus":_vm.visit.predictedAdmitStatus,"showConfidenceLevels":false}}):_vm._e()],1)],1),_c('InfoButton',{attrs:{"menuProps":new Set(['offset-x', 'right']),"text":_vm.visitPredictedStatusDisclaimer}})],1),(_vm.isModelV4Enabled && _vm.visit.predictedAdmitStatus)?_c('v-divider',{staticClass:"mx-n2"}):_vm._e(),(_vm.isModelV4Enabled && _vm.visit.predictedAdmitStatus)?_c('div',{staticClass:"text-label neutral-700--text pt-2"},[(_vm.isPredictedAdmitStatusBelowThreshold)?_c('span',[_vm._v(" "+_vm._s(_vm.predictedAdmitStatusBelowThresholdDisclaimer)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.predictedAdmitStatusAboveThresholdDisclaimer)+" ")])]):_vm._e(),(_vm.visit.predictedAdmitStatus)?_c('PercentageLineBar',{class:{
            'py-4': !_vm.isModelV4Enabled,
            'pt-4': _vm.isModelV4Enabled,
        },attrs:{"data-testid":"predicted-status-percentage-line-bar","text":`${_vm.isPredictedAdmitStatusBelowThreshold ? _vm.modelPredictedAdmitStatus : 'Confidence Level'}`,"color":_vm.getAdmitStatusConfidenceColor(_vm.nullishCoalesce(_vm.predictedAdmitStatusWithConfidence.confidencePercentage / 100, 0)),"percentage":_vm.predictedAdmitStatusWithConfidence.confidencePercentage}}):_vm._e(),(!_vm.isModelV4Enabled)?_c('v-divider',{staticClass:"ml-n2 py-2"}):_vm._e(),(!_vm.isModelV4Enabled)?_c('v-row',{staticClass:"d-flex flex-row justify-space-between px-2"},_vm._l((_vm.predictions),function(prediction,index){return _c('PercentageLineBar',{key:prediction.admitStatus,staticClass:"py-4 grow",class:{
                'pr-4': index !== _vm.predictions.length - 1,
            },attrs:{"data-testid":prediction.admitStatus + '-percentage-line-bar',"text":prediction.admitStatus,"color":_vm.getAdmitStatusConfidenceColor(prediction.confidencePercentage / 100),"percentage":prediction.confidencePercentage}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }