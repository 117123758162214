
import { formatDateTime } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import { ClinicalSummaryItemType } from '@/models';
import { MicrobiologyResult } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MicrobiologyDetailViewHeader',
    components: {
        AddToClinicalSummaryButton,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
    },
    props: {
        microbiologyId: String,
        microbiologyResult: Object as PropType<MicrobiologyResult>,
    },
    data: () => ({
        ClinicalSummaryItemType,
    }),
    methods: {
        formatDateTime,
    },
});
