
import { Role } from '@/auth/role.enum';
import InfoButton from '@/components/shared/InfoButton.vue';
import ReadmissionButton from '@/components/shared/ReadmissionButton.vue';
import ReadmissionCard from '@/components/shared/ReadmissionCard.vue';
import { formatPersonName, hoursInObsDisclaimer, withDefault } from '@/components/shared/utils';
import VisitOwnerSelector from '@/components/shared/VisitOwnerSelector.vue';
import { getVisitForPatientCard } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { Maybe, Query, Visit } from 'generated/graphql/graphql';
import Vue from 'vue';

enum State {
    VISIT_INFO,
    READMISSIONS,
}

export default Vue.extend({
    name: 'VisitCardButton',
    components: {
        VisitOwnerSelector,
        ReadmissionButton,
        ReadmissionCard,
        InfoButton,
    },
    data: () => ({
        hoursInObsDisclaimer,
        userStore: useUserStore(),
        showCard: false,
        visit: undefined as Maybe<Visit> | undefined,
        readmissions: [] as Visit[],
        physicianAdvisorType: Role.PHYSICIAN_ADVISOR,
        umNurseType: Role.UM_NURSE,
        featureStore: useFeatureStore(),
        state: State.VISIT_INFO,
        State,
    }),
    computed: {
        latestCaseNote(): Maybe<string> | undefined {
            return this.visit?.latestCaseNote;
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        isPhysicianAdvisor(): boolean {
            return this.userStore.currentRole === Role.PHYSICIAN_ADVISOR;
        },
    },
    methods: {
        withDefault,
        formatPersonName,
        onClick(): void {
            this.state = State.VISIT_INFO;
            this.getVisit();
        },
        back(): void {
            this.state = State.VISIT_INFO;
        },
        close(): void {
            this.showCard = false;
        },
        showReadmissions(visits: Visit[]): void {
            this.readmissions = visits;
            this.state = State.READMISSIONS;
        },
        async getVisit() {
            if (this.visit) return;

            const response = await this.$apollo.query<Query>({
                query: getVisitForPatientCard,
                variables: {
                    id: parseInt(this.$route.params.id),
                },
                fetchPolicy: 'no-cache',
            });
            this.visit = response.data.visit;
        },
    },
});
