
import { properCase } from '@/components/shared/utils';
import { VisitTimelineEventDetails } from '@/models';
import { useVisitTimelineFiltersStore } from '@/stores/VisitTimelineFiltersStore';
import Vue, { PropType } from 'vue';

type FilterObject = { [index: string]: string[] };

export default Vue.extend({
    name: 'VisitTimelineFilters',
    props: {
        events: Array as PropType<VisitTimelineEventDetails[]>,
    },
    data: () => ({
        visitTimelineFilterStore: useVisitTimelineFiltersStore(),
        show: false,
        disableResetButton: true,
    }),
    created() {
        this.visitTimelineFilterStore.$reset();
        this.visitTimelineFilterStore.$subscribe(() => {
            const values = Object.values(this.visitTimelineFilterStore.filters);
            for (let i = 0; i < values.length; i++) {
                if (values.at(i)?.length) {
                    this.disableResetButton = false;
                    break;
                }
                this.disableResetButton = true;
            }
        });
    },
    beforeUpdate() {
        if (!Object.keys(this.visitTimelineFilterStore.potentialFilters).length) this.loadItems();
        if (!Object.keys(this.visitTimelineFilterStore.filters).length) {
            const emptyFilterObject = Object.keys(this.visitTimelineFilterStore.potentialFilters).reduce((acc, curr) => {
                acc[curr] = [];
                return acc;
            }, {} as FilterObject);
            this.visitTimelineFilterStore.$patch({ filters: emptyFilterObject });
        }
    },
    methods: {
        properCase,
        close(): void {
            this.show = false;
        },
        toggleMenu(): void {
            this.show = !this.show;
        },
        loadItems(): void {
            this.events.forEach((event: VisitTimelineEventDetails): void => {
                if (event.type === 'DAY') return;
                const filterCategory = event.type;
                const filterSubCategory = event.header;
                if (this.visitTimelineFilterStore.potentialFilters[filterCategory]) {
                    if (this.visitTimelineFilterStore.potentialFilters[filterCategory].indexOf(event.header) === -1) {
                        this.visitTimelineFilterStore.potentialFilters[filterCategory].push(event.header);
                    }
                } else {
                    this.visitTimelineFilterStore.potentialFilters[filterCategory] = [filterSubCategory];
                }
            });
        },
        toggleItem(selection: string, value: string) {
            const valueIndex = this.visitTimelineFilterStore.filters[selection].indexOf(value);
            const arrCopy = [...this.visitTimelineFilterStore.filters[selection]];

            if (valueIndex > -1) {
                arrCopy.splice(valueIndex, 1);
            } else {
                arrCopy.push(value);
            }
            this.visitTimelineFilterStore.$patch({
                filters: {
                    ...this.visitTimelineFilterStore.filters,
                    [selection]: arrCopy,
                },
            });

            this.visitTimelineFilterStore.potentialFilters = { ...this.visitTimelineFilterStore.potentialFilters };
        },
        toggleCategory(selection: string) {
            const existingSelections = this.visitTimelineFilterStore.filters[selection];
            let newSelection = [] as string[];
            if (existingSelections?.length < this.visitTimelineFilterStore.potentialFilters[selection]?.length) {
                newSelection = [...this.visitTimelineFilterStore.potentialFilters[selection]];
            }
            this.visitTimelineFilterStore.$patch({
                filters: {
                    ...this.visitTimelineFilterStore.filters,
                    [selection]: newSelection,
                },
            });
            this.visitTimelineFilterStore.potentialFilters = { ...this.visitTimelineFilterStore.potentialFilters };
        },
    },
});
