
import { formatDateTime, formatTime, withDefault } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import VisitTimelineDetailView from '@/components/visit/timeline/VisitTimelineDetailView.vue';
import { ClinicalSummaryItemType, VisitTimelineEventDetails } from '@/models';
import { LabVitalsResult, Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitTimelineLabVitalsDetailView',
    components: {
        VisitTimelineDetailView,
        AddToClinicalSummaryButton,
    },
    props: {
        selectedEvent: {
            type: Object as PropType<VisitTimelineEventDetails>,
            required: true,
        },
    },
    data: () => ({
        ClinicalSummaryItemType,
        labVitalsResults: [] as LabVitalsResult[],
        isLoading: false,
        hover: false,
    }),
    computed: {
        header(): string {
            return this.selectedEvent.header;
        },
        subheader(): string {
            return `at ${formatDateTime(this.selectedEvent.timestamp)}`;
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler() {
                this.getLabVitalsResult(this.selectedEvent);
            },
        },
    },
    methods: {
        formatTime,
        withDefault,
        getLabVitalsResult(event: VisitTimelineEventDetails): Promise<void> {
            this.isLoading = true;
            return this.$apollo
                .query<Query>({
                    query: gql`
                        query TimelineLabVitalsResult($visitId: Int!, $category: String!, $date: String!) {
                            labVitalsResults(visitId: $visitId, category: $category, date: $date) {
                                id
                                name
                                date
                                value
                                units
                                referenceRange
                                status
                                abnormalFlags
                            }
                        }
                    `,
                    variables: {
                        visitId: parseInt(this.$route.params.id),
                        category: event.groupedByType,
                        date: event.groupedByDate,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.labVitalsResults = response.data.labVitalsResults;
                    this.isLoading = false;
                });
        },
    },
});
