
import ClinicalsDueIcon from '@/components/shared/ClinicalsDueIcon.vue';
import DischargeIcon from '@/components/shared/DischargeIcon.vue';
import NextReviewIcon from '@/components/shared/NextReviewIcon.vue';
import ScoreDots from '@/components/shared/ScoreDots.vue';
import {
    formatDate,
    formatDateTime,
    formatFacility,
    formatLocation,
    formatPersonName,
    nullishCoalesce,
    withDefault,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import VisitCardButton from '@/components/visit/info-bar/VisitCardButton.vue';
import { VisitInfoBarField } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { Visit } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitInfoBar',
    components: {
        ScoreDots,
        VisitCardButton,
        DischargeIcon,
        ClinicalsDueIcon,
        NextReviewIcon,
        VerticalField,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        displayFields: { type: Set<VisitInfoBarField>, required: true },
    },
    data: () => ({
        VisitInfoBarField,
        featureStore: useFeatureStore(),
    }),
    methods: {
        withDefault,
        formatPersonName,
        formatDateTime,
        formatDate,
        formatFacility,
        formatLocation,
        nullishCoalesce,
    },
});
