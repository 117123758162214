
import { deleteClinicalSummary } from '@/shared/mutations';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'DeleteClinicalSummaryDialog',
    props: {
        visitId: { type: Number, required: true },
        clinicalSummaryId: { type: Number, required: true },
    },
    data: () => ({
        isDialogOpen: false,
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
    }),
    methods: {
        cancel(): void {
            this.isDialogOpen = false;
        },
        async deleteClinicalSummary() {
            await this.$apollo.mutate<Mutation>({
                mutation: deleteClinicalSummary,
                variables: {
                    input: {
                        visitId: this.visitId,
                        clinicalSummaryId: this.clinicalSummaryId,
                    },
                },
            });
            this.isDialogOpen = false;
            this.$toast.success('Your Clinical Summary has been deleted!');
            this.visitStore.clinicalSummary = undefined;
            this.visitDrawerStore.closeDrawer();
        },
    },
});
