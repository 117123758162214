import IodineColors from '@/styles/colors';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowLeft as falArrowLeft,
    faBrainCircuit as falBrainCircuit,
    faCalendarXmark as falCalendarXmark,
    faCircleArrowUp as falCircleArrowUp,
    faCircleCheck as falCircleCheck,
    faCircleChevronRight as falCircleChevronRight,
    faCircleQuestion as falCircleQuestion,
    faClipboardList as falClipboardList,
    faCommentArrowUpRight as falCommentArrowUpRight,
    faFolderGear as falFolderGear,
    faFolderPlus as falFolderPlus,
    faHospitalUser as falHospitalUser,
    faListDropdown as falListDropdown,
    faNote as falNote,
    faPieChart as falPieChart,
    faTriangleExclamation as falTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import {
    faAddressCard,
    faArrowDownArrowUp,
    faArrowsRepeat,
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faBarsFilter,
    faCalendarClock,
    faCalendarDay,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronsLeft,
    faChevronsRight,
    faChevronUp,
    faCircleCheck,
    faCirclePlus,
    faCircleTrash,
    faCircleXmark,
    faClock,
    faCommentArrowUpRight,
    faEllipsisVertical,
    faFileCirclePlus,
    faFilesMedical,
    faFlaskVial,
    faHandHoldingMedical,
    faHandsHoldingChild,
    faHourglassClock,
    faHourglassHalf,
    faMagnifyingGlass,
    faMemo,
    faPenToSquare,
    faPills,
    faSquare,
    faThumbsDown,
    faThumbsUp,
    faTriangleExclamation,
    faUserMd,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCaretDown as fasCaretDown,
    faCaretUp as fasCaretUp,
    faCheckSquare as fasCheckSquare,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCirclePlus as fasCirclePlus,
    faEye as fasEye,
    faFlagPennant as fasFlagPennant,
    faTimesCircle as fasTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import { VBtn, VIcon, VSnackbar } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';

library.add(
    falArrowLeft,
    falBrainCircuit,
    falCalendarXmark,
    falCircleArrowUp,
    falCircleCheck,
    falCircleChevronRight,
    falCircleQuestion,
    falClipboardList,
    falCommentArrowUpRight,
    falFolderGear,
    falFolderPlus,
    falHospitalUser,
    falListDropdown,
    falPieChart,
    falNote,
    falTriangleExclamation,
    faAddressCard,
    faArrowsRepeat,
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faArrowDownArrowUp,
    faBarsFilter,
    faCalendarClock,
    faCalendarDay,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faChevronsLeft,
    faChevronsRight,
    faCircleCheck,
    faCirclePlus,
    faCircleTrash,
    faCircleXmark,
    faClock,
    faCommentArrowUpRight,
    faEllipsisVertical,
    faFlaskVial,
    faFileCirclePlus,
    faFilesMedical,
    faHandsHoldingChild,
    faHandHoldingMedical,
    faHourglassClock,
    faHourglassHalf,
    faMagnifyingGlass,
    faMemo,
    faPenToSquare,
    faPills,
    faSquare,
    faTriangleExclamation,
    faThumbsDown,
    faThumbsUp,
    faUserMd,
    faXmark,
    fasCaretDown,
    fasCheckSquare,
    fasChevronLeft,
    fasChevronRight,
    fasCircleInfo,
    fasCircleExclamation,
    fasEye,
    fasFlagPennant,
    fasTimesCircle,
    fasCirclePlus,
    fasCaretUp
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon,
    },
});

Vue.use(VuetifyToast, {
    timeout: 3000,
    classes: ['vuetify-toast'],
    shorts: {
        success: {
            color: 'white--text success-500',
            icon: '$success',
        },
        error: {
            color: 'white--text error-500',
            closeIcon: '$close',
            showClose: true,
        },
    },
});

export default new Vuetify({
    icons: {
        iconfont: 'faSvg',
        values: {
            success: {
                component: FontAwesomeIcon,
                props: {
                    icon: ['fa-regular', 'fa-circle-check'],
                },
            },
            close: {
                component: FontAwesomeIcon,
                props: {
                    icon: ['fa-regular', 'fa-xmark'],
                },
            },
        },
    },
    theme: {
        themes: {
            light: {
                primary: IodineColors['interactive-a400'],
                ...IodineColors,
            },
        },
        options: { customProperties: true },
    },
});
