enum DrawerType {
    _, // dummy type to prevent DOCUMENTATION from being 0, preventing the drawer from opening
    DOCUMENTATION = 'Documentation',
    MEDICATIONS = 'Medications',
    LABS = 'Lab Results',
    MICROBIOLOGY = 'Microbiology',
    CLINICAL_SUMMARY = 'Clinical Summary',
    CLINICAL_SUMMARY_CONFIRMATION = 'Review and Complete',
    CASE_NOTES = 'Case Notes',
    GEN_AI_SUMMARY = 'Chart Summary',
}

export default DrawerType;
