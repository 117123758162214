
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import { pluralize } from '@/components/shared/utils';
import VisitConditionDetailsSectionHeader from '@/components/visit/conditions/VisitConditionDetailsSectionHeader.vue';
import MedicationAdministrationsTable from '@/components/visit/medications/MedicationAdministrationsTable.vue';
import MedicationOrdersTable from '@/components/visit/medications/MedicationOrdersTable.vue';
import { ToggleButtonName, VisitConditionMedicationDataItem } from '@/models';
import { MedicationAdministration, MedicationOrder } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationsConditionDetailView',
    components: { ToggleButtons, MedicationAdministrationsTable, MedicationOrdersTable, VisitConditionDetailsSectionHeader },
    props: {
        supportingMedications: { type: Object as PropType<VisitConditionMedicationDataItem>, required: true },
        conditionName: { type: String, required: true },
    },
    data: () => ({
        medicationAdministrations: [] as MedicationAdministration[],
        medicationOrders: [] as MedicationOrder[],
        currentButton: undefined as ToggleButtonName | undefined,
        ToggleButtonName,
    }),
    computed: {
        noMedicationAdministrations(): boolean {
            return this.medicationAdministrations.length === 0;
        },
        noMedicationOrders(): boolean {
            return this.medicationOrders?.length === 0;
        },
        headerText(): string {
            const lengthText =
                this.currentButton === ToggleButtonName.MEDICATION_ADMINISTRATIONS
                    ? this.medicationAdministrations.length.toString()
                    : this.medicationOrders.length.toString();
            const typeText =
                this.currentButton === ToggleButtonName.MEDICATION_ADMINISTRATIONS
                    ? pluralize('Administration', this.medicationAdministrations.length)
                    : pluralize('Order', this.medicationOrders.length);
            return lengthText + ' Medication ' + typeText + ' for ' + this.conditionName;
        },
    },
    created() {
        this.medicationAdministrations = this.supportingMedications.medicationAdministrations ?? [];
        this.medicationOrders = this.supportingMedications.medicationOrders;
        if (this.medicationOrders.length === 0) {
            this.$emit('noData', true);
        }
        this.currentButton =
            this.medicationAdministrations.length > 0 ? ToggleButtonName.MEDICATION_ADMINISTRATIONS : ToggleButtonName.MEDICATION_ORDERS;
    },
    methods: {
        pluralize,
        toggleView(selectedTab: ToggleButtonName): void {
            this.currentButton = selectedTab;
        },
    },
});
