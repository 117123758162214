
import Loadable from '@/components/shared/Loadable.vue';
import { getAdmitStatusConfidenceColor, getRoundedPercentage } from '@/components/shared/utils';
import { PredictedAdmitStatus } from '@/models';
import { getCurrentAdmitStatusPrediction } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { AdmitStatusPrediction, Maybe, Query } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ConfidenceLevelsCard',
    components: {
        Loadable,
    },
    props: {
        visitId: { type: String, required: true },
        admitStatusPredictions: { type: Object as PropType<Maybe<AdmitStatusPrediction>>, required: false },
    },
    data: () => ({
        loading: true,
        currentAdmitStatusPredictions: undefined as Maybe<AdmitStatusPrediction> | undefined,
        featureStore: useFeatureStore(),
        modelV1PredictedStatuses: [
            PredictedAdmitStatus.INPATIENT.toLowerCase(),
            PredictedAdmitStatus.OBSERVATION.toLowerCase(),
            PredictedAdmitStatus.DISCHARGE.toLowerCase(),
        ],
    }),
    computed: {
        admitStatusConfidenceLevels(): Record<string, number> {
            const predictions = this.admitStatusPredictions ?? this.currentAdmitStatusPredictions;
            if (this.featureStore.isEnabled('ADMIT_STATUS_MODEL_VERSION_4') && predictions) {
                predictions.inpatientConfidence = predictions.inpatientConfidence ?? 0;
                predictions.observationConfidence = predictions.observationConfidence ?? 0;
                if (predictions.inpatientConfidence > predictions.observationConfidence) {
                    return { inpatient: predictions.inpatientConfidence };
                }
                return { observation: predictions.observationConfidence };
            }
            return this.modelV1PredictedStatuses.reduce<Record<string, number>>((confidenceLevels, status) => {
                const confidenceKey = (status + 'Confidence') as keyof AdmitStatusPrediction;
                confidenceLevels[status] = Number(predictions?.[confidenceKey]) || 0;
                return confidenceLevels;
            }, {});
        },
    },
    created() {
        if (!this.admitStatusPredictions && !this.currentAdmitStatusPredictions) {
            this.getPredictions();
        } else {
            this.loading = false;
        }
    },
    methods: {
        getAdmitStatusConfidenceColor,
        getRoundedPercentage,
        async getPredictions() {
            const response = await this.$apollo.query<Query>({
                query: getCurrentAdmitStatusPrediction,
                variables: {
                    id: +this.visitId,
                },
                fetchPolicy: 'no-cache',
            });
            this.currentAdmitStatusPredictions = response.data.visit?.currentAdmitStatusPrediction;
            this.$emit('currentAdmitStatusPredictions', this.currentAdmitStatusPredictions);
            this.loading = false;
        },
    },
});
