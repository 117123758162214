
import { defineComponent } from 'vue';

import { ADULT_AGE } from '@/models/visit/visit-summary/VisitPatientAge.model';

import { noAdultPredictedAdmitStatusDisclaimer, noChildPredictedAdmitStatusDisclaimer } from '@/components/shared/utils/model/Disclaimers';

export default defineComponent({
    name: 'NoPredictionIcon',
    props: {
        patientAge: {
            type: Number,
            required: false,
            default: undefined,
            validator: (value) => value === undefined || value === null || typeof value === 'number',
        },
        size: {
            type: String,
            required: false,
        },
    },
    computed: {
        effectivePatientAge(): number {
            // this.patientAge == null checks for both null and undefined.
            return this.patientAge == null ? ADULT_AGE : this.patientAge;
        },
        tooltipText(): string {
            return this.effectivePatientAge < ADULT_AGE ? noChildPredictedAdmitStatusDisclaimer : noAdultPredictedAdmitStatusDisclaimer;
        },
        iconName(): string {
            return this.effectivePatientAge < ADULT_AGE ? 'fa-regular fa-hands-holding-child' : 'fa-regular fa-hourglass-clock';
        },
        iconTestId(): string {
            return this.effectivePatientAge < ADULT_AGE ? 'no-prediction-icon-child' : 'no-prediction-icon-adult';
        },
        iconSize(): string {
            return this.size ?? 'lg';
        },
    },
});
