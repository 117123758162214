import { render, staticRenderFns } from "./ClinicalSummaryListView.vue?vue&type=template&id=0c4edd2c&scoped=true&"
import script from "./ClinicalSummaryListView.vue?vue&type=script&lang=ts&"
export * from "./ClinicalSummaryListView.vue?vue&type=script&lang=ts&"
import style0 from "./ClinicalSummaryListView.vue?vue&type=style&index=0&id=0c4edd2c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4edd2c",
  null
  
)

export default component.exports