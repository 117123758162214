enum VisitInfoBarField {
    PATIENT_INFO,
    SCORE_DOTS,
    VISIT_CARD,
    ADMIT_STATUS,
    ADMIT_DATE,
    ADMIT_TIME,
    FACILITY,
    LOCATION,
    LOS,
    AUTHORIZATION,
    PAYER,
    CLINICALS_DUE,
    INLINE_DOB,
    NEXT_REVIEW_DATE,
}

export default VisitInfoBarField;
