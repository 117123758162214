import gql from 'graphql-tag';

export const sendClinicalSummary = gql`
    mutation SendClinicalSummary($input: ClinicalSummarySendInput!) {
        sendClinicalSummary(input: $input) {
            id
        }
    }
`;

export const deleteClinicalSummary = gql`
    mutation DeleteClinicalSummary($input: ClinicalSummaryDeleteInput!) {
        deleteClinicalSummary(input: $input) {
            id
        }
    }
`;

export const addClinicalSummaryItem = gql`
    mutation AddClinicalSummaryItem($input: ClinicalSummaryItemInput!) {
        addClinicalSummaryItem(input: $input) {
            id
        }
    }
`;

export const deleteClinicalSummaryItem = gql`
    mutation DeleteClinicalSummaryItem($input: ClinicalSummaryItemInput!) {
        deleteClinicalSummaryItem(input: $input) {
            id
        }
    }
`;

export const saveClinicalSummaryNote = gql`
    mutation SaveClinicalSummaryNote($input: ClinicalSummaryNoteInput!) {
        saveClinicalSummaryNote(input: $input) {
            id
        }
    }
`;

export const createReviewFeedback = gql`
    mutation CreateReviewFeedback($input: ReviewFeedbackInput!) {
        createReviewFeedback(input: $input) {
            id
        }
    }
`;

export const setNextReviewDate = gql`
    mutation SetNextReviewDate($input: NextReviewDateInput!) {
        setNextReviewDate(input: $input) {
            nextReviewDate
        }
    }
`;

export const addAvoidableDay = gql`
    mutation AddAvoidableDay($input: AvoidableDayAddInput!) {
        addAvoidableDay(input: $input) {
            avoidableDate
            category
            id
            reason
        }
    }
`;

export const editAvoidableDay = gql`
    mutation EditAvoidableDay($input: AvoidableDayEditInput!) {
        editAvoidableDay(input: $input) {
            avoidableDate
            category
            id
            reason
        }
    }
`;

export const deleteAvoidableDay = gql`
    mutation DeleteAvoidableDay($input: AvoidableDayDeleteInput!) {
        deleteAvoidableDay(input: $input) {
            id
        }
    }
`;

export const assignPayerRule = gql`
    mutation AssignPayerRule($input: PayerRuleAssignmentInput!) {
        assignPayerRule(input: $input)
    }
`;

export const assignFaxNumber = gql`
    mutation AssignFaxNumber($input: PayerFaxNumberAssignmentInput!) {
        assignFaxNumber(input: $input) {
            id
            faxNumber
        }
    }
`;

export const assignFaxNumberInClinicalSummaryBuilder = gql`
    mutation AssignFaxNumberInClinicalSummaryBuilder($input: PayerFaxNumberAssignmentInput!) {
        assignFaxNumber(input: $input) {
            id
            faxNumber
        }
    }
`;

export const removeFaxNumber = gql`
    mutation RemoveFaxNumberAssignment($input: PayerFaxNumberAssignmentRemovalInput!) {
        removeFaxNumberAssignment(input: $input)
    }
`;

export const acknowledgeFailedFax = gql`
    mutation AcknowledgeFailedFax($visitId: Int!) {
        acknowledgeFailedFax(visitId: $visitId)
    }
`;

export const resetDemoData = gql`
    mutation ResetDemoData {
        resetDemoData
    }
`;

export const updateUserPreferences = gql`
    mutation UpdateUserPreferences($input: UserPreferencesInput!) {
        updateUserPreferences(input: $input)
    }
`;

export const completeFirstLevelReview = gql`
    mutation CompleteFirstLevelReview($visitId: Int!, $visitSnapshotId: Int!, $notes: String) {
        completeFirstLevelReview(visitId: $visitId, visitSnapshotId: $visitSnapshotId, notes: $notes) {
            id
        }
    }
`;

export const completeSecondLevelReview = gql`
    mutation CompleteSecondLevelReview($notes: String, $recommendedAdmitStatus: String!, $visitId: Int!, $visitSnapshotId: Int!) {
        completeSecondLevelReview(
            notes: $notes
            recommendedAdmitStatus: $recommendedAdmitStatus
            visitId: $visitId
            visitSnapshotId: $visitSnapshotId
        ) {
            id
        }
    }
`;

export const escalateFirstLevelReview = gql`
    mutation EscalateFirstLevelReview($visitId: Int!, $notes: String, $visitSnapshotId: Int!) {
        escalateFirstLevelReview(visitId: $visitId, notes: $notes, visitSnapshotId: $visitSnapshotId) {
            id
        }
    }
`;

export const startReview = gql`
    mutation StartReview($visitId: Int!) {
        createVisitSnapshot(visitId: $visitId) {
            id
        }
    }
`;

export const readVisitComment = gql`
    mutation ReadVisitComment($input: ReadVisitCommentInput!) {
        readVisitComment(input: $input)
    }
`;

export const saveVisitComment = gql`
    mutation SaveVisitComment($input: VisitCommentInput!) {
        saveVisitComment(input: $input) {
            id
            version
            creator {
                firstName
                lastName
            }
            createdDate
            modifiedDate
            comment
            escalatedTo {
                firstName
                lastName
            }
            reviewOutcome
            reviewRecommendedAdmitStatus
            originalComment {
                createdDate
            }
        }
    }
`;

export const assignVisitOwner = gql`
    mutation AssignVisitOwner($caregiverId: Int, $visitId: Int!, $role: String!) {
        assignVisitOwner(caregiverId: $caregiverId, visitId: $visitId, role: $role) {
            umNurseOwnerId
            physicianAdvisorOwnerId
        }
    }
`;

export const logoutUser = gql`
    mutation LogoutUser {
        logoutUser
    }
`;
