
import DocumentConditionDetailView from '@/components/visit/documentation-and-services/DocumentConditionDetailView.vue';
import ServicesConditionDetailView from '@/components/visit/documentation-and-services/ServicesConditionDetailView.vue';
import LabsConditionDetailView from '@/components/visit/labs/LabsConditionDetailView.vue';
import MedicationsConditionDetailView from '@/components/visit/medications/MedicationsConditionDetailView.vue';
import { VisitConditionDataSection, VisitConditionTab } from '@/models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitConditionDetailsSection',
    components: {
        MedicationsConditionDetailView,
        LabsConditionDetailView,
        ServicesConditionDetailView,
        DocumentConditionDetailView,
    },
    props: {
        section: {
            type: Object as PropType<VisitConditionDataSection>,
            default: () => ({} as VisitConditionDataSection),
            required: true,
        },
        conditionName: String,
    },
    data: () => ({
        VisitConditionTab,
        noData: false,
    }),
    methods: {
        displayImage() {
            this.noData = true;
        },
    },
});
