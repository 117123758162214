
import { formatPersonName, nullishCoalesce, pluralize } from '@/components/shared/utils';
import VisitConditions from '@/components/visit/conditions/VisitConditions.vue';
import VisitSummariesDetailView from '@/components/visit/gen-ai/VisitSummariesDetailView.vue';
import VisitChiefComplaint from '@/components/visit/patient-summary/VisitChiefComplaint.vue';
import VisitPredictedStatus from '@/components/visit/patient-summary/VisitPredictedStatus.vue';
import VisitSummary from '@/components/visit/patient-summary/VisitSummary.vue';
import VisitTimeline from '@/components/visit/timeline/VisitTimeline.vue';
import { DrawerType, Tab } from '@/models';
import { InitData, VisitSummaryApp, VisitSummaryServiceFunctionOverride } from '@/services/VisitSummaryApp';
import { saveClinicalSummaryNote, updateUserPreferences } from '@/shared/mutations';
import { getClinicalSummaryDraft, getGenAiTermsAcceptance, getImportMapUrl, getTextGenServiceEndpoint } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { NavigationGuardMixin } from '@okta/okta-vue';
import { ClinicalSummary, Mutation, Query } from 'generated/graphql/graphql';

export default NavigationGuardMixin.extend({
    name: 'VisitPatientSummary',
    components: {
        VisitChiefComplaint,
        VisitConditions,
        VisitSummary,
        VisitTimeline,
        VisitPredictedStatus,
        VisitSummariesDetailView,
    },
    computed: {
        visit() {
            return this.visitStore.visit;
        },
        isGenAIEnabled() {
            return this.featureStore.isEnabled('GEN_AI') && this.userStore.canViewTextGen;
        },
    },
    async beforeDestroy() {
        if (this.isGenAIEnabled) {
            await VisitSummaryApp.resetApp(VisitSummaryApp.visitSummaryAppName);
            await VisitSummaryApp.resetApp(VisitSummaryApp.newSinceLastReviewAppName);
        }
    },
    async created() {
        this.userStore.$subscribe(async () => {
            if (this.isGenAIEnabled && !(await VisitSummaryApp.isAppRunning(await this.getImportMapUrl()))) {
                await this.startVisitSummaryApp();
            }
        });
    },
    async mounted() {
        if (this.isGenAIEnabled && !(await VisitSummaryApp.isAppRunning(await this.getImportMapUrl()))) {
            await this.startVisitSummaryApp();
        }
    },
    data: () => ({
        DrawerType,
        VisitSummaryApp,
        visitStore: useVisitStore(),
        userStore: useUserStore(),
        selectedTab: Tab.POSSIBLE_COMPLICATING_CONDITIONS,
        visitConditionCount: 0,
        featureStore: useFeatureStore(),
        visitDrawerStore: useVisitDrawerStore(),
        Tab,
    }),
    methods: {
        formatPersonName,
        pluralize,
        nullishCoalesce,
        async getImportMapUrl() {
            const response = await this.$apollo.query<Query>({ query: getImportMapUrl, fetchPolicy: 'no-cache' });
            return response.data.config.importMap?.url ?? '';
        },
        async startVisitSummaryApp() {
            const response = await this.$apollo.query<Query>({ query: getTextGenServiceEndpoint, fetchPolicy: 'cache-first' });
            const endpoint = response.data.config.textGenService?.endpoint ?? '';
            const token = (await this.$auth.token.getWithoutPrompt()).tokens.accessToken?.accessToken ?? '';
            const initData: InitData = {
                endpoint,
                visitId: +this.$route.params.id,
                token,
                divisionId: this.visitStore.visit?.division ?? '',
                previousReview: {
                    reviewDate: this.visitStore.visit?.lastReviewDate ?? '',
                    reviewer: formatPersonName(this.visitStore.visit?.lastReviewer ?? ''),
                },
                showWarningDialog: await this.displayGenAiWarningDialog(),
                transferContent: {
                    enabled: true,
                    label: VisitSummaryApp.transferContentLabel,
                },
            };
            const overrideFunctions: VisitSummaryServiceFunctionOverride = {
                transferContent: (content: string) => this.addToClinicalSummary(content),
                userAgreed: (accept: boolean) => this.handleUserTermsDecision(accept),
            };
            await VisitSummaryApp.init(initData, overrideFunctions);
            // we need to pause before activating to let all the MFE app registration code finish executing before we push this on the event loop
            setTimeout(() => {
                if (this.selectedTab !== Tab.NEW_SINCE_LAST_REVIEW) VisitSummaryApp.hideApp(VisitSummaryApp.newSinceLastReviewAppName);
                if (this.visitDrawerStore.drawer !== DrawerType.GEN_AI_SUMMARY)
                    VisitSummaryApp.hideApp(VisitSummaryApp.visitSummaryAppName);
                this.visitStore.$patch({
                    isVisitSummaryAppLoaded: true,
                });
            });
        },
        async addToClinicalSummary(content: string) {
            const visitId = +this.$route.params.id;
            const clinicalSummaryDraft: ClinicalSummary = await this.getClinicalSummary(visitId);
            const existingNote = clinicalSummaryDraft.note ? clinicalSummaryDraft.note + '\n\n' : '';
            await this.$apollo.mutate<Mutation>({
                mutation: saveClinicalSummaryNote,
                variables: {
                    input: {
                        visitId,
                        clinicalSummaryId: +clinicalSummaryDraft.id,
                        note: existingNote + content,
                    },
                },
            });
            clinicalSummaryDraft.note = existingNote + content;
            this.visitStore.$patch({
                clinicalSummary: clinicalSummaryDraft,
            });
            this.$toast.success('Added to Clinical Summary');
        },
        async getClinicalSummary(visitId: number): Promise<ClinicalSummary> {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalSummaryDraft,
                    variables: {
                        filter: {
                            visitId: visitId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.$patch({
                    clinicalSummary: response.data.clinicalSummaryDraft,
                });
            }
            return this.visitStore.clinicalSummary!;
        },
        async switchTab(tab: Tab) {
            if (this.selectedTab == Tab.NEW_SINCE_LAST_REVIEW && tab != Tab.NEW_SINCE_LAST_REVIEW) {
                await VisitSummaryApp.hideApp(VisitSummaryApp.newSinceLastReviewAppName);
            }
            this.selectedTab = tab;
        },
        displayConditionCount(conditionCount: number): void {
            this.visitConditionCount = conditionCount;
        },
        async displayGenAiWarningDialog(): Promise<boolean> {
            const response = await this.$apollo.query<Query>({
                query: getGenAiTermsAcceptance,
            });
            const acceptedGenAiTerms = !!response.data.userPreferences?.acceptedGenAiTerms;
            VisitSummaryApp.acceptedSharedDialog = acceptedGenAiTerms;
            return !acceptedGenAiTerms;
        },
        handleUserTermsDecision(accept: boolean): void {
            VisitSummaryApp.acceptedSharedDialog = accept;
            if (accept) {
                this.$apollo.mutate<Mutation>({
                    mutation: updateUserPreferences,
                    variables: {
                        input: {
                            acceptedGenAiTerms: true,
                        },
                    },
                });
                return;
            }
            if (this.selectedTab === Tab.NEW_SINCE_LAST_REVIEW) {
                this.switchTab(Tab.POSSIBLE_COMPLICATING_CONDITIONS);
            } else if (this.visitDrawerStore.drawer === DrawerType.GEN_AI_SUMMARY) {
                this.visitDrawerStore.closeDrawer();
            }
        },
    },
});
