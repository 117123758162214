
import ConfidenceLevelsCard from '@/components/shared/ConfidenceLevelsCard.vue';
import { getAdmitStatusConfidenceLevel } from '@/components/shared/utils';
import { AdmitStatusConfidenceCategory, admitStatusConfidenceLevels } from '@/models';
import { AdmitStatusPrediction, Maybe } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ConfidenceIcon',
    components: {
        ConfidenceLevelsCard,
    },
    props: {
        visitId: { type: String, required: true },
        confidence: { type: Number, required: true, default: 0 },
        admitStatusPredictions: { type: Object as PropType<Maybe<AdmitStatusPrediction>>, required: false },
        showConfidenceLevels: { type: Boolean, required: false, default: true },
    },
    data: () => ({
        currentAdmitStatusPredictions: undefined as Maybe<AdmitStatusPrediction> | undefined,
        AdmitStatusConfidenceCategory,
        hover: false,
    }),
    computed: {
        level(): AdmitStatusConfidenceCategory {
            return getAdmitStatusConfidenceLevel(this.confidence, admitStatusConfidenceLevels);
        },
    },
    created() {
        if (this.admitStatusPredictions) {
            this.currentAdmitStatusPredictions = this.admitStatusPredictions;
        }
    },
    methods: {
        storeAdmitStatusPredictions(predictions: AdmitStatusPrediction) {
            this.currentAdmitStatusPredictions = predictions;
        },
    },
});
