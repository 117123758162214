
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import VisitSummarySection from '@/components/visit/patient-summary/VisitSummarySection.vue';
import { getVisitSummary } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { Query, VisitSummary } from 'generated/graphql/graphql';
import Vue from 'vue';

enum Tab {
    PRIMARY,
    SECONDARY,
}

export default Vue.extend({
    name: 'VisitSummary',
    components: {
        VisitSummarySection,
        FeatureToggle,
    },
    data: () => ({
        featureStore: useFeatureStore(),
        soi: [] as VisitSummary[],
        ios: [] as VisitSummary[],
        selectedTab: Tab.PRIMARY,
        Tab,
    }),
    created() {
        this.loadData(parseInt(this.$route.params.id));
    },
    methods: {
        async loadData(id: number) {
            const response = await this.$apollo.query<Query>({
                query: getVisitSummary,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });

            if (!response.data.visit?.summaries) return;

            for (const summary of response.data.visit.summaries) {
                switch (summary.type) {
                    case 'SOI':
                        this.soi.push(summary);
                        break;
                    case 'IOS':
                        this.ios.push(summary);
                        break;
                    default:
                        console.log('Unknown summary type: ' + summary.type);
                        break;
                }
            }
        },
        switchTab(tab: Tab): void {
            this.selectedTab = tab;
        },
    },
});
