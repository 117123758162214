
import { Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitTimelineSearchField',
    data: () => ({
        showField: false,
        searchText: '',
        timeout: undefined as NodeJS.Timeout | undefined,
        searchResultDocumentIds: new Set<string>(),
    }),
    computed: {
        searchResultCount(): string {
            return this.searchText ? this.searchResultDocumentIds?.size + ' Result(s)' : '';
        },
        minimumTwoCharacters(): boolean {
            return this.searchText.length === 1;
        },
        noResults(): boolean {
            return this.searchText.length > 1 && this.searchResultDocumentIds.size === 0;
        },
    },
    methods: {
        toggleField(): void {
            this.showField = !this.showField;
            this.$emit('expanded', this.showField);
        },
        debounceSearch(): void {
            this.$emit('searchText', '');
            this.$emit('loading', true);
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                await this.searchDocuments();
                this.$emit('loading', false);
            }, 500);
        },
        async searchDocuments() {
            if (this.searchText.trim().length < 2) {
                this.searchResultDocumentIds.clear();
                return;
            }
            await this.$apollo
                .query<Query>({
                    query: gql`
                        query DocumentIdsBySearchText($visitId: Int!, $searchText: String!) {
                            documentIdsBySearchText(visitId: $visitId, searchText: $searchText)
                        }
                    `,
                    variables: {
                        visitId: parseInt(this.$route.params.id),
                        searchText: this.searchText,
                    },
                })
                .then((response) => {
                    this.searchResultDocumentIds = new Set(response.data.documentIdsBySearchText);
                    this.$emit('searchResults', this.searchResultDocumentIds);
                    this.$emit('searchText', this.searchText);
                });
        },
        clearSearch() {
            this.searchText = '';
            this.searchResultDocumentIds.clear();
            this.$emit('searchResults', this.searchResultDocumentIds);
            this.$emit('searchText', this.searchText);
        },
    },
});
