
import { EventBus, Events } from '@/components/shared/event-bus/EventBus';
import { formatDateTime, withDefault } from '@/components/shared/utils';
import LabResultItem from '@/components/visit/labs/LabResultItem.vue';
import { ClinicalSummaryItemType } from '@/models';
import { LabVitalsResult, LabVitalsResults, LabVitalsResultsTable } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'LabsTable',
    components: {
        LabResultItem,
    },
    props: {
        labResults: {
            type: Object as PropType<LabVitalsResultsTable>,
            required: true,
        },
        highlightEntityIds: {
            type: Set<string>,
            default: () => new Set<string>(),
            required: false,
        },
        abnormalLabsFilter: Boolean,
        height: String,
    },
    data: () => ({
        ClinicalSummaryItemType,
        hover: false,
        selectedIds: [] as string[],
    }),
    computed: {
        filterLabResults(): LabVitalsResultsTable {
            if (!this.abnormalLabsFilter) {
                return this.labResults;
            } else {
                let filterColumnIndexes: number[] = [];
                for (let colIndex = 0; colIndex < this.labResults.columns.length; colIndex++) {
                    if (!this.columnContainsAbnormals(this.labResults, colIndex)) {
                        filterColumnIndexes.push(colIndex);
                    }
                }
                if (filterColumnIndexes.length === 0) {
                    this.$emit('abnormalLabsCount', this.labResults.rows.length);
                    return this.labResults;
                }
                const notInFilterColumns = (element: any, index: number) => !filterColumnIndexes.includes(index);
                const filteredRow = (row: any) => ({
                    ...row,
                    results: row.results.filter(notInFilterColumns),
                });
                const filteredRows = this.labResults.rows.map(filteredRow);
                let abnormalRows: any = [];
                filteredRows.filter((row) => this.rowContainsAbnormals(row)).forEach((row) => abnormalRows.push(row));
                this.$emit('abnormalLabsCount', abnormalRows.length);
                return {
                    rows: abnormalRows,
                    columns: this.labResults.columns.filter(notInFilterColumns),
                } as LabVitalsResultsTable;
            }
        },
    },
    mounted() {
        EventBus.$on(Events.CLINICAL_SUMMARY_SUBMISSION, this.clearSelectedLabs);
    },
    beforeDestroy() {
        EventBus.$off(Events.CLINICAL_SUMMARY_SUBMISSION, this.clearSelectedLabs);
    },
    created() {
        this.selectedIds = [];
    },
    methods: {
        formatDateTime,
        withDefault,
        rowContainsAbnormals(row: LabVitalsResults): boolean {
            return row.results.some((result) => result?.abnormalFlags);
        },
        columnContainsAbnormals(table: LabVitalsResultsTable, colIndex: number): boolean {
            for (let row of table.rows) {
                if (row.results[colIndex]?.abnormalFlags) {
                    return true;
                }
            }
            return false;
        },
        addLabToSelected(lab: LabVitalsResult) {
            this.selectedIds.push(lab.id);
            this.emitUpdatedAddToClinicalSummaryInputs(this.selectedIds);
        },
        removeLabFromSelected(lab: LabVitalsResult) {
            this.selectedIds = this.selectedIds.filter((id) => id !== lab.id);
            this.emitUpdatedAddToClinicalSummaryInputs(this.selectedIds);
        },
        addSingleSelectedLab(lab: LabVitalsResult) {
            this.selectedIds = [lab.id];
            this.emitUpdatedAddToClinicalSummaryInputs(this.selectedIds);
        },
        clearSelectedLabs() {
            this.selectedIds = [];
            this.emitUpdatedAddToClinicalSummaryInputs(this.selectedIds);
        },
        emitUpdatedAddToClinicalSummaryInputs(ids: string[]) {
            this.$emit('updateSelectedItems', ids);
        },
    },
});
