
import ClinicalSummaryListItem from '@/components/visit/clinical-summary/ClinicalSummaryListItem.vue';
import { ClinicalSummaryStatusType } from '@/models';
import { getClinicalSummaries } from '@/shared/queries';
import { ClinicalSummary, Maybe, Query, Visit } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryListView',
    components: {
        ClinicalSummaryListItem,
    },
    data: () => ({
        visit: undefined as Maybe<Visit> | undefined,
        clinicalSummaries: [] as ClinicalSummary[],
    }),
    computed: {
        clinicalSummariesCount(): number {
            return this.clinicalSummaries.length;
        },
    },
    async created() {
        this.loadClinicalSummaries();
    },
    methods: {
        async loadClinicalSummaries() {
            await this.$apollo
                .query<Query>({
                    query: getClinicalSummaries,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                            status: ClinicalSummaryStatusType.SENT,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.clinicalSummaries = this.clinicalSummaries.concat(response.data.clinicalSummaries);
                });
        },
    },
});
