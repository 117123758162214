
import { EventBus, Events } from '@/components/shared/event-bus/EventBus';
import VisitInfoBar from '@/components/visit/info-bar/VisitInfoBar.vue';
import VisitDrawer from '@/components/visit/VisitDrawer.vue';
import { VisitInfoBarField } from '@/models';
import { startReview } from '@/shared/mutations';
import { getVisitNextReviewDate, getVisitPayerRules } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { NavigationGuardMixin } from '@okta/okta-vue';
import { Maybe, Mutation, Query, Visit } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import moment from 'moment';

export default NavigationGuardMixin.extend({
    name: 'VisitView',
    components: {
        VisitInfoBar,
        VisitDrawer,
    },
    provide: {
        showAddToClinicalSummaryButton: true,
        showDeleteFromClinicalSummaryButton: false,
    },
    data: () => ({
        featureStore: useFeatureStore(),
        visit: undefined as Maybe<Visit> | undefined,
        visitStore: useVisitStore(),
        clinicalsDue: false,
        visitInfoBarFields: new Set<VisitInfoBarField>([
            VisitInfoBarField.PATIENT_INFO,
            VisitInfoBarField.SCORE_DOTS,
            VisitInfoBarField.VISIT_CARD,
            VisitInfoBarField.ADMIT_STATUS,
            VisitInfoBarField.ADMIT_DATE,
            VisitInfoBarField.ADMIT_TIME,
            VisitInfoBarField.FACILITY,
            VisitInfoBarField.LOCATION,
            VisitInfoBarField.LOS,
            VisitInfoBarField.AUTHORIZATION,
            VisitInfoBarField.PAYER,
        ]),
    }),
    created() {
        EventBus.$on(Events.NEXT_REVIEW_DATE_SET, this.updateNextReviewDate);
    },
    beforeDestroy() {
        EventBus.$off(Events.NEXT_REVIEW_DATE_SET, this.updateNextReviewDate);
    },
    async mounted() {
        await this.loadVisit(parseInt(this.$route.params.id));
        this.loadRules();
        this.startReview(parseInt(this.$route.params.id));
        this.showNextReviewDateBadge();
    },
    destroyed() {
        this.visitStore.$reset();
    },
    methods: {
        async loadVisit(id: number) {
            const response = await this.$apollo.query<Query>({
                query: gql`
                    query Visit($id: Int!) {
                        visit(id: $id) {
                            id
                            encounterNo
                            admitDate
                            chiefComplaint
                            los
                            predictedLos
                            priorityScore
                            admitStatus
                            predictedAdmitStatus
                            predictedAdmitStatusConfidence
                            predictedAdmitStatusExplanation
                            dischargeDate
                            authStatus
                            authNumber
                            lastReviewDate
                            lastReviewer {
                                firstName
                                lastName
                            }
                            division
                            latestCaseNote
                            lastClinicalsSentDate
                            lastFaxRequestFailed
                            nextReviewDate
                            patient {
                                firstName
                                middleName
                                lastName
                                gender
                                age
                                mrn
                                dob
                            }
                            location {
                                facility {
                                    name
                                }
                                poc {
                                    name
                                }
                                bed
                                room
                            }
                            hospitalService {
                                name
                            }
                            primaryPayer {
                                name
                                code
                                faxNumbers {
                                    id
                                    faxNumber
                                    order
                                }
                            }
                            secondaryPayer {
                                name
                            }
                            attending {
                                firstName
                                lastName
                            }
                            currentAdmitStatusPrediction {
                                observationConfidence
                                inpatientConfidence
                                dischargeConfidence
                            }
                            previousVisit {
                                id
                                admitDate
                                dischargeDate
                            }
                        }
                    }
                `,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({ visit: response.data.visit });
            this.visit = response.data.visit;
        },
        async updateNextReviewDate() {
            const response = await this.$apollo.query<Query>({
                query: getVisitNextReviewDate,
                variables: {
                    id: +this.$route.params.id,
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({ visit: response.data.visit });
            if (this.visit && response.data.visit?.nextReviewDate) {
                this.visit.nextReviewDate = response.data.visit?.nextReviewDate;
                this.showNextReviewDateBadge();
            }
        },
        async loadRules() {
            const response = await this.$apollo.query<Query>({
                query: getVisitPayerRules,
                variables: {
                    filter: {
                        ids: [+this.$route.params.id],
                    },
                },
                fetchPolicy: 'no-cache',
            });
            if (response.data.visitPayerRules.at(0)?.clinicalsAreDue) {
                this.visitInfoBarFields.add(VisitInfoBarField.CLINICALS_DUE);
                //due to limitations of reactivity on Set as prop, need to create a new Set
                this.visitInfoBarFields = new Set(this.visitInfoBarFields);
            }
        },
        startReview(visitId: number) {
            this.visitStore.visitSnapshotId = this.$apollo
                .mutate<Mutation>({
                    mutation: startReview,
                    variables: {
                        visitId: visitId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    return response.data?.createVisitSnapshot.id;
                });
        },
        showNextReviewDateBadge() {
            if (
                this.featureStore.isEnabled('NEXT_REVIEW_DATE') &&
                this.visit?.nextReviewDate !== null &&
                this.visit?.nextReviewDate !== undefined &&
                moment().isSameOrBefore(moment(this.visit.nextReviewDate), 'day')
            ) {
                this.visitInfoBarFields.add(VisitInfoBarField.NEXT_REVIEW_DATE);
            } else {
                this.visitInfoBarFields.delete(VisitInfoBarField.NEXT_REVIEW_DATE);
            }
            this.visitInfoBarFields = new Set(this.visitInfoBarFields);
        },
    },
});
