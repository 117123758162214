
import { VisitSummaryApp } from '@/services/VisitSummaryApp';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitSummariesDetailView',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        VisitSummaryApp,
        appId: VisitSummaryApp.appId,
        appName: '',
    }),
    destroyed() {
        VisitSummaryApp.hideApp(this.appName);
    },
    async mounted() {
        this.appName =
            this.id == VisitSummaryApp.newSinceLastReviewAppContainerId
                ? VisitSummaryApp.newSinceLastReviewAppName
                : VisitSummaryApp.visitSummaryAppName;
        this.appId += this.appName;
        await VisitSummaryApp.mountApp(this.appName, this.id, this.appId);
    },
});
