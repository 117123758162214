
import DocumentDrawerButton from '@/components/shared/DocumentDrawerButton.vue';
import { formatDate, formatPersonName, formatTime, withDefault } from '@/components/shared/utils';
import DeleteFromClinicalSummaryButton from '@/components/visit/clinical-summary/DeleteFromClinicalSummaryButton.vue';
import { ClinicalSummaryItemType, DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { DocumentUnion } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryDocumentationTable',
    components: {
        DocumentDrawerButton,
        DeleteFromClinicalSummaryButton,
    },
    props: {
        documentation: Array<DocumentUnion>,
    },
    data: () => ({
        ClinicalSummaryItemType,
        hover: false,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        documentRows() {
            return this.documentation.map((doc) => {
                if ('quote' in doc) {
                    return { ...doc.document, ...doc };
                }
                return doc;
            });
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        withDefault,
        getQuote(document: DocumentUnion) {
            if (document.__typename === 'DocumentQuote') {
                return {
                    documentId: document.documentId,
                    quote: document.quote,
                    startRange: document.startRange,
                    endRange: document.endRange,
                };
            }
        },
        getType(typename: string | undefined) {
            if (typename === 'Document') {
                return ClinicalSummaryItemType.DOCUMENT;
            }
            return ClinicalSummaryItemType.DOCUMENT_QUOTE;
        },
        openDrawer(id: string): void {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.DOCUMENTATION,
                params: {
                    documentId: id,
                },
                drawerProps: {
                    dark: true,
                    large: true,
                    header: {
                        title: 'Document Preview',
                    },
                },
                breadcrumbs: [
                    {
                        drawer: DrawerType.CLINICAL_SUMMARY,
                        params: this.visitDrawerStore.params,
                        drawerProps: { dark: true },
                    },
                ],
            });
        },
    },
});
