import { EventBus, Events } from '@/components/shared/event-bus/EventBus';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';

const DEFAULT_TIMEOUT_DURATION = 900000; // 15 minutes default
const DEFAULT_TIMEOUT_ENABLED = false;

const eventNames = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'scroll'];

export default class SessionManager {
    private activityTimeout = DEFAULT_TIMEOUT_DURATION;
    private isTimeoutEnabled = DEFAULT_TIMEOUT_ENABLED;
    private activityTimeoutID?: number;
    private apollo: ApolloClient<NormalizedCacheObject>;

    constructor(apollo: ApolloClient<NormalizedCacheObject>) {
        this.apollo = apollo;

        // Binding event handlers
        this.resetActivityTimer = this.resetActivityTimer.bind(this);
        this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

        this.initEventListeners();
    }

    private initEventListeners() {
        // Attach event listeners
        eventNames.forEach((eventName) => {
            window.addEventListener(eventName, this.resetActivityTimer);
        });

        // Subscribe to login event
        EventBus.$on(Events.USER_LOGGED_IN, this.onUserLoggedIn);
    }

    private onUserLoggedIn = async () => {
        await this.getSessionSettings();
    };

    private async getSessionSettings() {
        try {
            const GET_SESSION_SETTINGS = gql`
                query GetSessionSettings {
                    getIdleSessionTimeoutMinutes
                    isIdleSessionTimeoutOn
                }
            `;

            const response = await this.apollo.query<Query>({
                query: GET_SESSION_SETTINGS,
                fetchPolicy: 'no-cache',
            });

            this.activityTimeout = response.data.getIdleSessionTimeoutMinutes * 60 * 1000;
            this.isTimeoutEnabled = response.data.isIdleSessionTimeoutOn;
        } catch (error) {
            console.error('Error fetching session settings:', error);
            // Handle error and set default timeout value here.
            this.activityTimeout = DEFAULT_TIMEOUT_DURATION;
            this.isTimeoutEnabled = DEFAULT_TIMEOUT_ENABLED;
        }
        // Ensure the timer is reset with the new or default session settings
        this.resetActivityTimer();
    }

    private resetActivityTimer = () => {
        clearTimeout(this.activityTimeoutID);

        this.activityTimeoutID = setTimeout(() => {
            this.handleInactivity();
        }, this.activityTimeout) as unknown as number;
    };

    private handleInactivity() {
        if (!this.isTimeoutEnabled) return;
        EventBus.$emit(Events.USER_INACTIVITY);
    }

    public destroy() {
        // Unsubscribe from login event
        EventBus.$off(Events.USER_LOGGED_IN, this.onUserLoggedIn);

        // Cleanup event listeners
        eventNames.forEach((eventName) => {
            window.removeEventListener(eventName, this.resetActivityTimer);
        });
    }
}
