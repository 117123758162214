
import { EventBus, Events } from '@/components/shared/event-bus/EventBus';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import SearchField from '@/components/titlebar/SearchField.vue';
import { logoutUser } from '@/shared/mutations';
import { getHelpLinks } from '@/shared/queries';
import { useUserStore } from '@/stores/UserStore';
import { HelpLink, Mutation, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'TitleBar',
    components: { SearchField, FeatureToggle },
    data: () => ({
        userStore: useUserStore(),
        helpLinks: [] as HelpLink[],
    }),
    async created() {
        EventBus.$on(Events.USER_INACTIVITY, this.logout);
        await this.refreshHelpLinks();
    },
    beforeDestroy() {
        EventBus.$off(Events.USER_INACTIVITY, this.logout);
    },
    methods: {
        async logout(): Promise<void> {
            await this.$apollo.mutate<Mutation>({
                mutation: logoutUser,
            });
            await this.$auth.signOut();
            this.userStore.logout();
        },
        async refreshHelpLinks(): Promise<void> {
            const value = await this.$apollo.query<Query>({ query: getHelpLinks, fetchPolicy: 'no-cache' });
            this.helpLinks = value.data.helpLinks;
        },
    },
});
