
import { Query, Visit } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
    name: 'ReadmissionButton',
    props: {
        visitId: String,
    },
    data: () => ({
        visits: undefined as Visit[] | undefined,
    }),
    created() {
        this.getRecentVisits();
    },
    methods: {
        getRecentVisits(): void {
            if (this.visits) return;

            this.$apollo
                .query<Query>({
                    query: gql`
                        query VisitsForReadmissionButton($id: Int!) {
                            visit(id: $id) {
                                readmissions {
                                    id
                                    encounterNo
                                    preDischargeAdmitStatus
                                    dischargeDate
                                    los
                                }
                            }
                        }
                    `,
                    variables: {
                        id: parseInt(this.visitId),
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.visits = response.data.visit?.readmissions ?? [];
                });
        },
        onClick(): void {
            this.$emit('show', this.visits);
        },
    },
});
