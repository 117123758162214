
import CaseNotesDetailView from '@/components/visit/case-notes/CaseNotesDetailView.vue';
import ClinicalSummaryBuilderView from '@/components/visit/clinical-summary/ClinicalSummaryBuilderView.vue';
import ClinicalSummaryConfirmationView from '@/components/visit/clinical-summary/ClinicalSummaryConfirmationView.vue';
import DocumentDrawerDetailView from '@/components/visit/documentation-and-services/DocumentDrawerDetailView.vue';
import DataDrawer from '@/components/visit/drawer/DataDrawer.vue';
import VisitSummariesDetailView from '@/components/visit/gen-ai/VisitSummariesDetailView.vue';
import LabsDrawerDetailView from '@/components/visit/labs/LabsDrawerDetailView.vue';
import MedicationsDrawerDetailView from '@/components/visit/medications/MedicationsDrawerDetailView.vue';
import MicrobiologyDrawerDetailView from '@/components/visit/microbiology/MicrobiologyDrawerDetailView.vue';
import { DrawerType } from '@/models';
import { VisitSummaryApp } from '@/services/VisitSummaryApp';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitDrawer',
    components: {
        CaseNotesDetailView,
        ClinicalSummaryBuilderView,
        ClinicalSummaryConfirmationView,
        DataDrawer,
        DocumentDrawerDetailView,
        LabsDrawerDetailView,
        MedicationsDrawerDetailView,
        MicrobiologyDrawerDetailView,
        VisitSummariesDetailView,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            required: true,
        },
    },
    data: () => ({
        DrawerType,
        VisitSummaryApp,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    destroyed() {
        this.visitDrawerStore.$reset();
    },
});
