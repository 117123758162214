import { InputValidationRule } from 'vuetify';

export const phoneNumberValidator: InputValidationRule = (value) => {
    const faxNumberRegex = /^\d\d\d-\d\d\d-\d\d\d\d$/;
    return faxNumberRegex.test(value) || 'Use format XXX-XXX-XXXX';
};

export const uniqueCurriedValidator =
    (options: any[]): InputValidationRule =>
    (value) => {
        return !options.includes(value) || 'Must be unique';
    };
