
import Loadable from '@/components/shared/Loadable.vue';
import { getConditionConfidenceLevel, pluralize } from '@/components/shared/utils';
import VisitConditionChart from '@/components/visit/conditions/VisitConditionChart.vue';
import VisitConditionConfidence from '@/components/visit/conditions/VisitConditionConfidence.vue';
import VisitConditionDetailsSection from '@/components/visit/conditions/VisitConditionDetailsSection.vue';
import VisitConditionDisclaimerButton from '@/components/visit/conditions/VisitConditionDisclaimerButton.vue';
import { VisitConditionDataSection, VisitConditionDetails, VisitConditionMedicationDataItem, VisitConditionTab } from '@/models';
import { getVisitConditions, getVisitConditionSupportingData } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { ChartData } from 'chart.js';
import { LabVitalsResultsTable, MedicationAdministration, MedicationOrder, Mention, Query, ServiceOrder } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitConditions',
    components: {
        Loadable,
        VisitConditionConfidence,
        VisitConditionDetailsSection,
        VisitConditionChart,
        VisitConditionDisclaimerButton,
    },
    data: () => ({
        conditions: [] as VisitConditionDetails[],
        conditionChart: undefined as ChartData | undefined,
        conditionColors: undefined as Map<string, string> | undefined,
        selectedCondition: undefined as VisitConditionDetails | undefined,
        isDetailViewOpen: false,
        featureStore: useFeatureStore(),
        selectedSection: undefined as VisitConditionDataSection | undefined,
        selectedTab: '',
        loading: true,
    }),
    computed: {
        showChart(): boolean {
            return this.featureStore.isEnabled('CONDITION_CHART') && !!this.conditionChart;
        },
    },
    async created() {
        await Promise.allSettled([
            this.loadConditions(parseInt(this.$route.params.id)),
            this.loadConditionChart(parseInt(this.$route.params.id)),
        ]);
        this.loading = false;
    },
    methods: {
        getConditionConfidenceLevel,
        pluralize,
        showSection(section: VisitConditionDataSection): void {
            this.selectedTab = section.header;
            this.selectedSection = section;
        },
        async loadConditions(visitId: number): Promise<void> {
            const response = await this.$apollo.query<Query>({
                query: getVisitConditions,
                variables: {
                    visitId: visitId,
                },
                fetchPolicy: 'no-cache',
            });
            this.conditions = response.data.visitConditions.items;
            this.$emit('conditionCount', this.conditions.length);
        },
        async loadConditionChart(visitId: number): Promise<void> {
            if (!this.featureStore.isEnabled('CONDITION_CHART')) return;

            const response = await this.$apollo.query<Query>({
                query: gql`
                    query VisitConditionChart($visitId: Int!) {
                        visit(id: $visitId) {
                            conditionChart
                        }
                    }
                `,
                variables: {
                    visitId: visitId,
                },
                fetchPolicy: 'no-cache',
            });
            if (response.data.visit?.conditionChart) {
                this.conditionChart = JSON.parse(response.data.visit.conditionChart);
                this.conditionColors = this.parseConditionColors(this.conditionChart);
            }
        },
        parseConditionColors(conditionChart: ChartData | undefined): Map<string, string> | undefined {
            if (!conditionChart) return undefined;
            return new Map<string, string>(conditionChart.datasets.map((c) => [c.label as string, c.backgroundColor as string]));
        },
        loadSupportingData(condition: VisitConditionDetails): Promise<void> {
            return this.$apollo
                .query<Query>({
                    query: getVisitConditionSupportingData,
                    variables: {
                        id: parseInt(condition.id),
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(async (response) => {
                    const supportingData = response.data.visitCondition?.supportingData;

                    const labsSection = this.parseSupportingLabResults(
                        supportingData?.labVitalsResultsTable ?? ({} as LabVitalsResultsTable)
                    );
                    condition.supportingDataSections = [
                        labsSection,
                        this.parseSupportingMedications(
                            supportingData?.medicationOrders ?? [],
                            supportingData?.medicationAdministrations ?? []
                        ),
                        this.parseSupportingMentions(supportingData?.mentions ?? []),
                        this.parseSupportingServices(supportingData?.serviceOrders ?? []),
                    ];
                    this.selectedSection = labsSection;
                    this.selectedTab = labsSection.header;
                });
        },
        parseSupportingLabResults(labsTable: LabVitalsResultsTable): VisitConditionDataSection {
            return {
                header: VisitConditionTab.LABS,
                items: labsTable,
            };
        },
        parseSupportingMedications(orders: MedicationOrder[], administrations: MedicationAdministration[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.MEDICATIONS,
                items: {
                    medicationOrders: orders,
                    medicationAdministrations: administrations,
                } as VisitConditionMedicationDataItem,
            };
        },

        parseSupportingMentions(list: Mention[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.DOCUMENTATION,
                items: list,
            };
        },
        parseSupportingServices(list: ServiceOrder[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.SERVICES,
                items: list,
            };
        },
        async openItem(condition: VisitConditionDetails): Promise<void> {
            await this.loadSupportingData(condition);
            this.isDetailViewOpen = true;
            this.selectedCondition = condition;
        },
        closeItem(): void {
            this.isDetailViewOpen = false;
        },
    },
});
