
import { formatDateTime, formatPersonName } from '@/components/shared/utils';
import { saveVisitComment } from '@/shared/mutations';
import { Mutation, VisitComment } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'CaseNote',
    props: {
        note: {
            type: Object as PropType<VisitComment>,
            required: true,
        },
        deleteMode: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        hover: false,
        editing: false,
        saving: false,
        showMenu: false,
        originalCommentBeforeEdit: '',
        menuPositionX: undefined as number | undefined,
        menuPositionY: undefined as number | undefined,
    }),
    methods: {
        formatDateTime,
        formatPersonName,
        showOptions(event: MouseEvent) {
            this.showMenu = true;
            this.menuPositionX = event.clientX;
            this.menuPositionY = event.clientY;
        },
        async saveNote() {
            try {
                this.saving = true;
                const response = await this.$apollo.mutate<Mutation>({
                    mutation: saveVisitComment,
                    variables: {
                        input: {
                            id: +this.note.id,
                            visitId: +this.$route.params.id,
                            comment: this.note.comment,
                        },
                    },
                });
                if (response.data?.saveVisitComment) {
                    this.note.version = response.data?.saveVisitComment.version;
                    this.note.modifiedDate = response.data?.saveVisitComment.modifiedDate;
                }
            } finally {
                this.saving = false;
                this.editing = false;
                this.$emit('editing', this.editing);
            }
        },
        editNote() {
            this.editing = true;
            this.$emit('editing', this.editing);
            this.originalCommentBeforeEdit = this.note.comment;
        },
        cancelEdit() {
            this.editing = false;
            this.$emit('editing', this.editing);
            this.note.comment = this.originalCommentBeforeEdit;
            this.originalCommentBeforeEdit = '';
        },
    },
});
