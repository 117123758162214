
import { Map, validVisitSummaryItemDescriptionJson } from '@/components/shared/utils';
import VisitSummaryItemView from '@/components/visit/patient-summary/VisitSummaryItem.vue';
import VisitSummaryMedicalConceptItemGroup from '@/components/visit/patient-summary/VisitSummaryMedicalConceptItemGroup.vue';
import { DescribedItem, DescribedItemType, DrawerType, VisitSummaryItemType } from '@/models';
import { getVisitSummaryDocumentedConcept, getVisitSummaryLabVitalsResult } from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { DocumentedConcept, LabVitalsResult, Maybe, Query, VisitSummary, VisitSummaryItem } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

interface ItemMapping<E> {
    getEntity: (item: VisitSummaryItem) => Promise<E>;
}

export default Vue.extend({
    name: 'VisitSummarySection',
    components: {
        VisitSummaryMedicalConceptItemGroup,
        VisitSummaryItemView,
    },
    props: {
        summary: {
            type: Object as PropType<VisitSummary>,
            default: () => ({} as VisitSummary),
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        expanded: true,
        itemMapper: {} as Map<string, ItemMapping<any>>,
        visitDrawerStore: useVisitDrawerStore(),
        VisitSummaryItemType,
    }),
    computed: {
        hasItems() {
            return !!this.summary.items?.length;
        },
    },
    created() {
        this.itemMapper = {
            lab_result: {
                getEntity: async (item) => {
                    return await this.getLabVitalsResult(parseInt(item.referenceId as string));
                },
            } as ItemMapping<LabVitalsResult>,
            medication_administration: {
                getEntity: async (item) => {
                    return item;
                },
            } as ItemMapping<VisitSummaryItem>,
            medication_order: {
                getEntity: async (item) => {
                    return item;
                },
            } as ItemMapping<VisitSummaryItem>,
            medication: {
                getEntity: async (item) => {
                    return item;
                },
            } as ItemMapping<VisitSummaryItem>,
            documented_mention: {
                getEntity: async (item) => {
                    return this.getDocumentedConcept(item.referenceId as string);
                },
            } as ItemMapping<DocumentedConcept>,
            observation_order: {
                getEntity: async (item) => {
                    return item;
                },
            } as ItemMapping<VisitSummaryItem>,
            microbiology: {
                getEntity: async (item) => {
                    return item;
                },
            } as ItemMapping<VisitSummaryItem>,
        };
    },
    methods: {
        validVisitSummaryItemDescriptionJson,
        async openItem(item: VisitSummaryItem, id?: string): Promise<void> {
            if (validVisitSummaryItemDescriptionJson(item)) {
                await this.openGroupedItem(item, id);
            } else {
                await this.openSingleton(item);
            }
        },
        async openGroupedItem(item: VisitSummaryItem, id?: string) {
            if (item.type === VisitSummaryItemType.MEDICAL_CONCEPT && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        // if opening via group link, use first item
                        documentId: id ?? item.descriptionJson.items[0].id,
                        medicalConceptId: item.referenceId,
                        mentionText: item.name,
                    },
                });
            } else if (item.type === VisitSummaryItemType.OBSERVATION_ORDER && item.referenceId) {
                // This will likely change in the future to accomodate child items,
                // but for now, this will not break and will still open the document that was clicked
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        documentId: item.referenceId,
                    },
                });
            } else if (
                (item.type === VisitSummaryItemType.MEDICATION_ORDER || item.type === VisitSummaryItemType.MEDICATION_ADMINISTRATION) &&
                item.referenceId
            ) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.MEDICATIONS,
                    params: {
                        type: item.type,
                        referenceId: id ?? item.descriptionJson.items[0].id,
                        medicationName: item.name,
                        medicationGenericName: item.referenceId,
                    },
                });
            } else if (item.type === VisitSummaryItemType.LAB_RESULT && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.LABS,
                    params: {
                        categoryName: item.referenceId,
                        highlightEntityIds: new Set<string>(
                            item.descriptionJson.items.map((item: DescribedItem<DescribedItemType.LAB_RESULT>) => item.id)
                        ),
                    },
                });
            } else if (item.type === VisitSummaryItemType.MICROBIOLOGY && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.MICROBIOLOGY,
                    params: {
                        microbiologyId: item.referenceId,
                    },
                });
            }
        },
        async openSingleton(item: VisitSummaryItem) {
            const entity = await this.itemMapper[item.type]?.getEntity(item);
            if (item.type === VisitSummaryItemType.DOCUMENTED_MENTION) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        documentId: entity.documentId,
                        medicalConceptId: entity.medicalConceptId,
                    },
                });
            } else if (item.type === VisitSummaryItemType.OBSERVATION_ORDER && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        documentId: item.referenceId,
                    },
                });
            } else if (
                (item.type === VisitSummaryItemType.MEDICATION_ORDER ||
                    item.type === VisitSummaryItemType.MEDICATION_ADMINISTRATION ||
                    item.type === VisitSummaryItemType.MEDICATION) &&
                item.referenceId
            ) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.MEDICATIONS,
                    params: {
                        type: item.type,
                        referenceId: item.referenceId,
                        medicationName: item.name,
                    },
                });
            } else if (item.type === VisitSummaryItemType.LAB_RESULT) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.LABS,
                    params: {
                        categoryName: entity.category,
                        highlightEntityIds: new Set<string>(item.referenceId ? [item.referenceId] : []),
                    },
                });
            } else if (item.type === VisitSummaryItemType.MICROBIOLOGY && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.MICROBIOLOGY,
                    params: {
                        microbiologyId: item.referenceId,
                    },
                });
            } else if (item.type === VisitSummaryItemType.MEDICAL_CONCEPT && item.referenceId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        medicalConceptId: item.referenceId,
                        mentionText: item.name,
                    },
                });
            }
        },
        async getLabVitalsResult(id: number): Promise<Maybe<LabVitalsResult> | undefined> {
            const response = await this.$apollo.query<Query>({
                query: getVisitSummaryLabVitalsResult,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.labVitalsResult;
        },
        async getDocumentedConcept(id: string): Promise<Maybe<DocumentedConcept> | undefined> {
            const response = await this.$apollo.query<Query>({
                query: getVisitSummaryDocumentedConcept,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.documentedConcept;
        },
    },
});
