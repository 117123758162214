
import { Role } from '@/auth/role.enum';
import { NULLABLE } from '@/components/shared/utils';
import AdminButton from '@/components/sidebar/AdminButton.vue';
import AnalyticsButton from '@/components/sidebar/AnalyticsButton.vue';
import AvoidableDaysDialog from '@/components/sidebar/AvoidableDaysDialog.vue';
import CaseNotesButton from '@/components/sidebar/CaseNotesButton.vue';
import ClinicalSummaryButton from '@/components/sidebar/ClinicalSummaryButton.vue';
import ClinicalSummaryHistoryButton from '@/components/sidebar/ClinicalSummaryHistoryButton.vue';
import CompleteReviewDialog from '@/components/sidebar/CompleteReviewDialog.vue';
import EscalateToPADialog from '@/components/sidebar/EscalateToPADialog.vue';
import GenAiButton from '@/components/sidebar/GenAiButton.vue';
import PatientSummaryButton from '@/components/sidebar/PatientSummaryButton.vue';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import WorklistButton from '@/components/sidebar/WorklistButton.vue';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'SideBar',
    components: {
        AdminButton,
        AnalyticsButton,
        AvoidableDaysDialog,
        CaseNotesButton,
        ClinicalSummaryButton,
        ClinicalSummaryHistoryButton,
        CompleteReviewDialog,
        EscalateToPADialog,
        PatientSummaryButton,
        SideBarButton,
        WorklistButton,
        GenAiButton,
    },
    data: () => ({
        userStore: useUserStore(),
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
        expanded: false,
        navButtonRoutes: new Set<NULLABLE<string>>(['visit', 'patientsummary', 'clinicalsummarylist', 'clinicalsummarylistopen']),
        toolEnabledRoutes: new Set<NULLABLE<string>>(['visit', 'patientsummary', 'clinicalsummarylist', 'clinicalsummarylistopen']),
        adminRoutes: new Set<NULLABLE<string>>(['worklist', 'admin']),
        worklistRoutes: new Set<NULLABLE<string>>(['worklist', 'admin']),
        analyticsEnabledRoutes: new Set<NULLABLE<string>>(['worklist', 'analytics']),
    }),
    computed: {
        displayTools(): boolean {
            return this.toolEnabledRoutes.has(this.$route.name);
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        displayAdminButton(): boolean {
            return this.adminRoutes.has(this.$route.name) && (this.userStore.isAdmin || this.userStore.isManager);
        },
        displayNavButtons(): boolean {
            return this.navButtonRoutes.has(this.$route.name);
        },
        displayAnalyticsButton(): boolean {
            return this.userStore.canViewAnalytics && this.analyticsEnabledRoutes.has(this.$route.name);
        },
        displayBackToWorklistButton(): boolean {
            return !this.worklistRoutes.has(this.$route.name);
        },
        displayGenAiButton() {
            return this.featureStore.isEnabled('GEN_AI') && this.userStore.canViewTextGen;
        },
    },
});
