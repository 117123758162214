import { ClinicalSummary, Maybe, Visit } from 'generated/graphql/graphql';
import { defineStore } from 'pinia';

export const useVisitStore = defineStore('visit', {
    state: () => ({
        visit: undefined as Maybe<Visit> | undefined,
        clinicalSummary: undefined as Maybe<ClinicalSummary> | undefined,
        visitSnapshotId: undefined as Promise<any> | undefined,
        isVisitSummaryAppLoaded: false,
    }),
});
