import { render, staticRenderFns } from "./ConfigureRulesDialog.vue?vue&type=template&id=cc58959c&scoped=true&"
import script from "./ConfigureRulesDialog.vue?vue&type=script&lang=ts&"
export * from "./ConfigureRulesDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigureRulesDialog.vue?vue&type=style&index=0&id=cc58959c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc58959c",
  null
  
)

export default component.exports