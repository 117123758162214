
import { formatDate, withDefault } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import DeleteFromClinicalSummaryButton from '@/components/visit/clinical-summary/DeleteFromClinicalSummaryButton.vue';
import MedicationOrderDetailsButton from '@/components/visit/medications/MedicationOrderDetailsButton.vue';
import { ClinicalSummaryItemType } from '@/models';
import { MedicationOrder } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationOrdersTable',
    components: {
        MedicationOrderDetailsButton,
        AddToClinicalSummaryButton,
        DeleteFromClinicalSummaryButton,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
        showDeleteFromClinicalSummaryButton: { default: false },
    },
    props: {
        medicationOrders: [] as PropType<MedicationOrder[]>,
        height: String,
        displayDetailsButton: { type: Boolean, default: true },
    },
    data: () => ({
        ClinicalSummaryItemType,
        hover: false,
    }),
    methods: {
        formatDate,
        withDefault,
    },
});
