export const visitPredictedStatusDisclaimer =
    "The admission status predictions are based on available data and statistical models, and should be used as a general guide  only. They are not intended to be a definitive determination of a patient's admission status, and should not be relied upon    as the sole basis for making decisions about patient care.";

export const hoursInObsDisclaimer =
    'This calculation is derived from your hospital system’s ADT feeds, which may differ from what is captured by orders within the EMR. Please remember to verify the dates and times on orders.';

export const predictedAdmitStatusBelowThresholdDisclaimer =
    'At this time, there is not enough clinical evidence to confidently support a status conversion. For transparency, we wanted to provide our confidence in the likelihood of a status conversion to the alternative status based on the available data. Please use your clinical judgement when reviewing.';

export const predictedAdmitStatusAboveThresholdDisclaimer =
    'At this time, our minimum confidence to prioritize this patient for a potential status conversion has been met. For transparency, we wanted to provide our confidence in the likelihood of a status conversion. As always, please use your clinical judgment when reviewing.';

export const noChildPredictedAdmitStatusDisclaimer =
    'We do not currently make admission status predictions on pediatric patients. Other scoring parameters will still take effect to potentially prioritize this visit. Please use your clinical judgement when reviewing.';

export const noAdultPredictedAdmitStatusDisclaimer =
    "We are evaluating this visit but don't have enough confidence yet based on the current clinical data to make an admission status prediction. Please use your clinical judgement when reviewing.";
