
import ExplainableTextInput from '@/components/shared/ExplainableTextInput.vue';
import { phoneNumberValidator, withDefault } from '@/components/shared/utils';
import { assignFaxNumber, removeFaxNumber } from '@/shared/mutations';
import { FaxNumber, Mutation } from 'generated/graphql/graphql';
import _ from 'underscore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AssignFaxNumberDialog',
    components: {
        ExplainableTextInput,
    },
    props: {
        disabled: Boolean,
        selectedPayerCodes: { type: Array<string>, required: true },
        selectedFaxNumbers: { type: Array<FaxNumber>, required: true },
    },
    data: () => ({
        autofocus: false,
        hover: false,
        isDialogOpen: false,
        payerCodes: [] as string[],
        faxNumbers: [] as FaxNumber[],
        newFaxNumber: null as string | null,
        newFaxNumberCount: 0,
        faxNumbersToDelete: [] as string[],
        selectedPrimaryFaxNumber: undefined as FaxNumber | undefined,
    }),
    watch: {
        selectedPayerCodes() {
            this.populatePayerCodes();
        },
        selectedFaxNumbers() {
            if (!this.disabled) {
                this.populateFaxNumbers();
            }
        },
        selectedPrimaryFaxNumber() {
            if (this.selectedPrimaryFaxNumber === undefined) {
                this.selectedPrimaryFaxNumber = this.faxNumbers.find((number) => number.order === 1) ?? this.faxNumbers.at(0);
            }
        },
    },
    created() {
        this.populateFaxNumbers();
        this.populatePayerCodes();
    },
    methods: {
        withDefault,
        phoneNumberValidator,
        isValidFaxNumber(faxNumber: string) {
            return phoneNumberValidator(faxNumber) === true && faxNumber !== this.newFaxNumber;
        },
        displayDeleteButton(faxNumberToDelete: FaxNumber): boolean {
            //cannot delete the primary selected fax number
            return faxNumberToDelete !== this.selectedPrimaryFaxNumber;
        },
        close(): void {
            //reset state
            this.populateFaxNumbers();
            this.selectedPrimaryFaxNumber = undefined;
            this.autofocus = false;
            this.isDialogOpen = false;
        },
        async removeFaxNumberAssignment() {
            await this.$apollo.mutate<Mutation>({
                mutation: removeFaxNumber,
                variables: {
                    input: {
                        faxNumbers: this.faxNumbersToDelete,
                        payerCodes: this.payerCodes,
                    },
                },
            });
        },
        async saveAssignment() {
            const faxNumbersAreValid = Array.from(this.faxNumbers).every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (!faxNumbersAreValid) {
                this.autofocus = true; //autofocus on invalid field
                this.$toast.error('Oops! Something is not right');
                return;
            }
            if (this.faxNumbersToDelete.length > 0) {
                this.removeFaxNumberAssignment();
            }
            //save primary fax number and create orders list
            const orders = this.faxNumbers.flatMap((val) => {
                if (val === this.selectedPrimaryFaxNumber) {
                    val.order = 1;
                    return 1;
                }
                val.order = null;
                return null;
            });
            //assign fax numbers
            const faxNumbersToSave = this.faxNumbers.map((number) => number.faxNumber);
            await this.$apollo.mutate<Mutation>({
                mutation: assignFaxNumber,
                variables: {
                    input: {
                        faxNumbers: faxNumbersToSave,
                        payerCodes: this.payerCodes,
                        orders,
                    },
                },
            });
            this.$emit('mutate', this.faxNumbers);
            this.$toast.success("eFax #'s successfully saved!");
            this.close();
        },
        populateFaxNumbers() {
            this.faxNumbers = [];
            if (this.selectedFaxNumbers?.length === 0) {
                this.newFaxNumberCount--;
                this.faxNumbers.push({ id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber);
                this.selectedPrimaryFaxNumber = Array.from(this.faxNumbers.values()).at(0);
            } else {
                this.selectedFaxNumbers.forEach((faxNumber) => {
                    const faxNumberClone = _.clone(faxNumber);
                    if (faxNumber.order === 1) {
                        this.selectedPrimaryFaxNumber = faxNumberClone;
                        this.faxNumbers.unshift(faxNumberClone); //primary is always displayed first
                    } else {
                        this.faxNumbers.push(faxNumberClone);
                    }
                });
            }
        },
        populatePayerCodes() {
            this.payerCodes = [];
            this.selectedPayerCodes.forEach((payerCode) => this.payerCodes.push(payerCode));
        },
        async deleteFaxNumber(faxNumber: FaxNumber) {
            if (this.selectedFaxNumbers.find((number) => number.faxNumber === faxNumber.faxNumber)) {
                //only perform delete mutation on numbers that were previously saved
                this.faxNumbersToDelete.push(faxNumber.faxNumber);
            }
            const delIndex = this.faxNumbers.findIndex((number) => number === faxNumber);
            this.$delete(this.faxNumbers, delIndex);
        },
        addNew() {
            //all existing fields must be populated and valid to add new
            const faxNumbersAreValid = Array.from(this.faxNumbers).every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (faxNumbersAreValid) {
                this.newFaxNumberCount--;
                this.faxNumbers.push({ id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber);
            }
        },
    },
});
