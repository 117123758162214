var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex flex-column px-4 pt-1",class:{
            'white--text neutral-700': _vm.darkMode,
            'neutral-900--text': !_vm.darkMode,
            'text-h5': _vm.largeMode,
            'text-subtitle-1': !_vm.largeMode,
            'pt-3': !_vm.shrink,
        },attrs:{"data-testid":"drawer-header-parent"}},[_c('div',{staticClass:"d-flex flex-row align-center in-line pr-3",class:_vm.largeMode ? 'pb-2' : 'pb-1'},[_c('div',{staticClass:"d-flex flex-row grow align-start",attrs:{"data-testid":"drawer-header"}},[(_vm.visitDrawerStore.breadcrumbs.length)?_c('DrawerBackButton'):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_vm._t("title-end")],2),_c('div',{staticClass:"d-flex flex-row justify-space-between in-line",attrs:{"data-testid":"drawer-subheader-container"}},[(_vm.chipText)?_c('span',{staticClass:"text-body-2 pb-2",attrs:{"data-testid":"drawer-subheader"}},[_c('v-chip',{staticClass:"text-capitalize",attrs:{"data-testid":"chip-text","small":"","color":"primary-50","text-color":"primary"}},[_vm._v(" "+_vm._s(_vm.chipText)+" ")]),_vm._v(" "+_vm._s(_vm.additionalText)+" ")],1):_vm._e(),_vm._t("end")],2)]),(!_vm.dark)?_c('v-divider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }