
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import { ClinicalSummaryItemType } from '@/models';
import { useVisitTimelineStore } from '@/stores/VisitTimelineStore';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitTimelineDetailView',
    components: {
        AddToClinicalSummaryButton,
    },
    props: {
        header: String,
        subheader: String,
        showAddToClinicalSummaryButton: Boolean,
        loading: Boolean,
        id: String,
        type: { type: String as PropType<ClinicalSummaryItemType> },
    },
    data: () => ({
        ClinicalSummaryItemType,
        visitTimelineStore: useVisitTimelineStore(),
    }),
    methods: {
        closeItem(): void {
            this.visitTimelineStore.reset();
        },
    },
});
