
import ConfidenceIcon from '@/components/shared/ConfidenceIcon.vue';
import InfoButton from '@/components/shared/InfoButton.vue';
import PercentageLineBar from '@/components/shared/PercentageLineBar.vue';
import {
    getAdmitStatusConfidenceColor,
    getRoundedPercentage,
    nullishCoalesce,
    predictedAdmitStatusAboveThresholdDisclaimer,
    predictedAdmitStatusBelowThresholdDisclaimer,
    visitPredictedStatusDisclaimer,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import { AdmitStatusWithConfidence, PredictedAdmitStatus } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { AdmitStatusPrediction, Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitPredictedStatus',
    components: {
        InfoButton,
        ConfidenceIcon,
        PercentageLineBar,
        VerticalField,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
    },
    data: () => ({
        predictedAdmitStatusAboveThresholdDisclaimer,
        predictedAdmitStatusBelowThresholdDisclaimer,
        visitPredictedStatusDisclaimer,
        featureStore: useFeatureStore(),
        predictions: [] as AdmitStatusWithConfidence[],
        predictedAdmitStatusWithConfidence: {
            admitStatus: '',
            confidencePercentage: 0,
        } as AdmitStatusWithConfidence,
    }),
    computed: {
        modelPredictedAdmitStatus(): string {
            const confidences = {
                [PredictedAdmitStatus.DISCHARGE]: this.visit.currentAdmitStatusPrediction?.dischargeConfidence ?? 0.0,
                [PredictedAdmitStatus.EMERGENCY]: this.visit.currentAdmitStatusPrediction?.edConfidence ?? 0.0,
                [PredictedAdmitStatus.INPATIENT]: this.visit.currentAdmitStatusPrediction?.inpatientConfidence ?? 0.0,
                [PredictedAdmitStatus.OBSERVATION]: this.visit.currentAdmitStatusPrediction?.observationConfidence ?? 0.0,
            };
            const maxPrediction = Object.entries(confidences).reduce((acc, entry) => (entry[1] > acc[1] ? entry : acc));
            return maxPrediction[0];
        },
        predictedAdmitStatus(): string {
            return this.visit.predictedAdmitStatus ?? 'Predicted Status Not Available';
        },
        isPredictedAdmitStatusBelowThreshold(): boolean {
            return this.visit.predictedAdmitStatus == this.visit.admitStatus;
        },
        isModelV4Enabled(): boolean {
            return this.featureStore.isEnabled('ADMIT_STATUS_MODEL_VERSION_4');
        },
    },
    created() {
        if (this.visit.predictedAdmitStatus) {
            this.predictedAdmitStatusWithConfidence = {
                admitStatus: this.visit.predictedAdmitStatus,
                confidencePercentage: getRoundedPercentage(this.visit.predictedAdmitStatusConfidence),
            };
        }

        if (!this.featureStore.isEnabled('ADMIT_STATUS_MODEL_VERSION_4')) {
            this.predictions = [PredictedAdmitStatus.OBSERVATION, PredictedAdmitStatus.INPATIENT, PredictedAdmitStatus.DISCHARGE]
                .filter((admitStatus) => this.visit.predictedAdmitStatus !== admitStatus)
                .map((admitStatus) => {
                    let confidencePercentage;
                    if (!this.visit.predictedAdmitStatus) {
                        confidencePercentage = 0;
                    } else {
                        const confidenceKey = (admitStatus.toLowerCase() + 'Confidence') as keyof AdmitStatusPrediction;
                        confidencePercentage = getRoundedPercentage(Number(this.visit.currentAdmitStatusPrediction?.[confidenceKey]));
                    }

                    return { admitStatus, confidencePercentage };
                });
        }
    },
    methods: {
        nullishCoalesce,
        getAdmitStatusConfidenceColor,
    },
});
