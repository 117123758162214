import gql from 'graphql-tag';

export const getVisitSummaryLabVitalsResult = gql`
    query VisitSummaryLabVitalsResult($id: Int!) {
        labVitalsResult(id: $id) {
            date
            category
        }
    }
`;

export const getVisitSummaryDocumentedConcept = gql`
    query VisitSummaryDocumentedConcept($id: ID!) {
        documentedConcept(id: $id) {
            documentId
            medicalConceptId
        }
    }
`;

export const getTimelineDocument = gql`
    query TimelineDocument($id: Int, $visitId: Int, $documentedConceptId: ID, $searchText: String, $filter: DocumentFilter) {
        document(id: $id, visitId: $visitId, documentedConceptId: $documentedConceptId, searchText: $searchText, filter: $filter) {
            body
            author {
                firstName
                lastName
            }
            date
            title
            id
        }
    }
`;

export const getTimelineEvents = gql`
    query VisitTimelineEvents($visitId: Int!) {
        visitTimelineEvents(visitId: $visitId) {
            items {
                id
                type
                header
                subHeader
                timestamp
                groupedByDate
                groupedByType
            }
        }
    }
`;

export const getRelatedDocumentedConcepts = gql`
    query RelatedDocumentedConcepts($medicalConceptId: ID!) {
        relatedDocumentedConcepts(medicalConceptId: $medicalConceptId) {
            items {
                id
                documentId
                medicalConceptId
            }
        }
    }
`;

export const getMetaDataForDocuments = gql`
    query MetaDataForDocuments($filter: DocumentsFilter!) {
        documents(filter: $filter) {
            items {
                id
                author {
                    firstName
                    lastName
                }
                date
                title
            }
        }
    }
`;

export const getMedicationDetailViewMedicationAdministration = gql`
    query MedicationDetailMedicationAdministration($id: Int!) {
        medicationAdministration(id: $id) {
            id
            name
            amount
            units
            date
            medicationOrder {
                id
                name
                route
                amount
                startDate
                endDate
                orderDate
                orderedBy
                interval
            }
        }
    }
`;

export const getMedicationDetailViewMedicationOrder = gql`
    query MedicationDetailMedicationOrder($id: Int!) {
        medicationOrder(id: $id) {
            id
            name
            route
            amount
            startDate
            endDate
            orderDate
            orderedBy
            interval
            medicationAdministrations {
                items {
                    id
                    name
                    amount
                    units
                    date
                }
            }
        }
    }
`;

export const getMedicationDetailViewMedicationOrders = gql`
    query MedicationDetailMedicationOrder($filter: MedicationOrdersFilter!) {
        medicationOrders(filter: $filter) {
            items {
                id
                name
                route
                amount
                startDate
                endDate
                orderDate
                orderedBy
                interval
                medicationAdministrations {
                    items {
                        id
                        name
                        amount
                        units
                        date
                    }
                }
            }
        }
    }
`;

export const getLabVitalsResultsTable = gql`
    query LabVitalsResultsTable($category: String!, $visitId: Int!) {
        labVitalsResultsTable(category: $category, visitId: $visitId) {
            columns
            rows {
                name
                results {
                    id
                    value
                    abnormalFlags
                    referenceRange
                    status
                }
            }
        }
    }
`;

export const getVisitConditions = gql`
    query VisitConditions($visitId: Int!) {
        visitConditions(visitId: $visitId) {
            items {
                id
                name
                evidence {
                    confidence
                    levels {
                        high
                        medium
                        low
                    }
                }
                documentation {
                    confidence
                    levels {
                        high
                        medium
                        low
                    }
                }
            }
        }
    }
`;

export const getVisitConditionSupportingData = gql`
    query VisitConditionSupportingData($id: Int!) {
        visitCondition(id: $id) {
            supportingData {
                labVitalsResultsTable {
                    columns
                    rows {
                        name
                        results {
                            id
                            value
                            abnormalFlags
                            referenceRange
                            status
                        }
                    }
                }
                medicationAdministrations {
                    id
                    name
                    amount
                    units
                    date
                    medicationOrder {
                        id
                        name
                        route
                        amount
                        startDate
                        endDate
                        orderDate
                        orderedBy
                        interval
                    }
                }
                medicationOrders {
                    id
                    name
                    route
                    amount
                    startDate
                    endDate
                    orderDate
                    orderedBy
                    interval
                    medicationAdministrations {
                        items {
                            id
                            name
                            amount
                            units
                            date
                        }
                    }
                }
                serviceOrders {
                    title
                    mostRecentOrderDate
                    documents {
                        id
                        title
                        author {
                            firstName
                            lastName
                        }
                        date
                    }
                }
                mentions {
                    medicalConceptId
                    phrase
                    context
                    documents {
                        id
                        title
                        author {
                            firstName
                            lastName
                        }
                        date
                    }
                }
            }
        }
    }
`;

export const getClinicalSummaries = gql`
    query ClinicalSummaries($filter: ClinicalSummariesFilter!) {
        clinicalSummaries(filter: $filter) {
            id
            creator {
                firstName
                lastName
            }
            createdDate
            fax {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
                faxNumber
            }
        }
    }
`;

export const getClinicalSummary = gql`
    query ClinicalSummary($filter: ClinicalSummaryFilter!) {
        clinicalSummary(filter: $filter) {
            creator {
                firstName
                lastName
            }
            createdDate
            id
            fax {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
                faxNumber
                pdf
            }
        }
    }
`;

export const getClinicalSummaryMicrobiologyResults = gql`
    query ClinicalSummaryMicrobiologyResults($filter: ClinicalSummaryFilter!) {
        clinicalSummaryMicrobiologyResults(filter: $filter) {
            id
            name
            date
            orderedBy
        }
    }
`;

export const getClinicalSummaryMedicationOrders = gql`
    query ClinicalSummaryMedicationOrders($filter: ClinicalSummaryFilter!) {
        clinicalSummaryMedicationOrders(filter: $filter) {
            items {
                id
                name
                route
                amount
                startDate
                endDate
            }
        }
    }
`;

export const getClinicalSummaryMedicationAdministrations = gql`
    query ClinicalSummaryMedicationAdministrations($filter: ClinicalSummaryFilter!) {
        clinicalSummaryMedicationAdministrations(filter: $filter) {
            items {
                id
                name
                amount
                units
                date
                medicationOrder {
                    orderedBy
                    interval
                }
            }
        }
    }
`;

export const getClinicalSummaryLabVitalsResults = gql`
    query ClinicalSummaryLabVitalsResults($filter: ClinicalSummaryFilter!) {
        clinicalSummaryLabVitalsResultsTable(filter: $filter) {
            columns
            rows {
                name
                results {
                    id
                    value
                    abnormalFlags
                    referenceRange
                    status
                }
            }
        }
    }
`;

export const getClinicalSummaryDocuments = gql`
    query ClinicalSummaryDocuments($filter: ClinicalSummaryFilter!) {
        clinicalSummaryDocuments(filter: $filter) {
            ... on Document {
                __typename
                author {
                    firstName
                    lastName
                }
                date
                id
                title
            }
            ... on DocumentQuote {
                __typename
                document {
                    author {
                        firstName
                        lastName
                    }
                    date
                    id
                    title
                }
                documentId
                endRange
                quote
                startRange
            }
        }
    }
`;

export const getClinicalSummaryDraft = gql`
    query ClinicalSummaryDraft($filter: ClinicalSummaryDraftFilter!) {
        clinicalSummaryDraft(filter: $filter) {
            id
            note
        }
    }
`;

export const getClinicalSummaryDraftWithPDF = gql`
    query ClinicalSummaryDraft($filter: ClinicalSummaryDraftFilter!) {
        clinicalSummaryDraft(filter: $filter) {
            id
            note
            pdf
            hash
        }
    }
`;

export const getPayers = gql`
    query Payers($filter: PayersFilter) {
        payers(filter: $filter) {
            name
            code
            faxNumbers {
                id
                faxNumber
                order
            }
            rule {
                minObservationHours
                maxObservationHours
                clinicalsInitialHours
                clinicalsOngoingHours
                explicitAuthorizations
                reviewIntervalHours
                postDischargeClinicalsHours
            }
        }
    }
`;

export const getAvoidableDays = gql`
    query AvoidableDays($filter: AvoidableDaysFilter!) {
        avoidableDays(filter: $filter) {
            id
            avoidableDate
            category
            reason
        }
    }
`;

export const getUserPreferences = gql`
    query UserPreferences {
        userPreferences {
            worklistFilters {
                fromAdmitDate
                toAdmitDate
                admitStatusCodes
                predictedAdmitStatusCodes
                facilityCodes
                locationCodes
                hospitalServiceCodes
                payerCodes
                payerCategoryCodes
                umNurseOwnerIds
            }
        }
    }
`;

export const getGenAiTermsAcceptance = gql`
    query UserPreferences {
        userPreferences {
            acceptedGenAiTerms
        }
    }
`;

export const getWorklistTabs = gql`
    query WorklistTabs($role: String!, $filters: VisitFiltersInput!) {
        worklistTabs(role: $role, filters: $filters) {
            name
            count
        }
    }
`;

export const getVisitPayerRules = gql`
    query VisitPayerRules($filter: VisitFiltersInput!) {
        visitPayerRules(filter: $filter) {
            clinicalsAreDue
            id
        }
    }
`;

export const getVisitNextReviewDate = gql`
    query VisitNextReviewDate($id: Int!) {
        visit(id: $id) {
            nextReviewDate
        }
    }
`;

export const getAnalytics = gql`
    query Analytics {
        analyticsItems {
            dashboards {
                title
                id
            }
            explores {
                title
                id
            }
            reports {
                title
                id
            }
        }
    }
`;

export const getExploreEmbedUrl = gql`
    query GetExploreEmbedUrl($id: String!) {
        getExploreEmbedUrl(exploreId: $id) {
            embedUrl
        }
    }
`;
export const getReportEmbedUrl = gql`
    query GetReportEmbedUrl($id: String!) {
        getReportEmbedUrl(lookId: $id) {
            embedUrl
        }
    }
`;
export const getDashboardEmbedUrl = gql`
    query GetDashboardEmbedUrl($id: String!) {
        getDashboardEmbedUrl(dashboardId: $id) {
            embedUrl
        }
    }
`;

export const getWorklistFilters = gql`
    query WorklistFilters {
        admitStatuses {
            code
            name
        }
        predictedAdmitStatuses {
            code
            name
        }
        facilities {
            code
            name
        }
        locations {
            code
            name
            facility {
                name
            }
        }
        payers {
            code
            name
        }
        payerCategories {
            code
            name
        }
        hospitalServices {
            code
            name
        }
        caregivers(type: "UM_NURSE") {
            id
            firstName
            lastName
        }
    }
`;

export const getVisitComments = gql`
    query VisitComments($filter: VisitCommentsFilter!) {
        visitComments(filter: $filter) {
            id
            version
            creator {
                firstName
                lastName
            }
            createdDate
            modifiedDate
            comment
            escalatedTo {
                firstName
                lastName
            }
            reviewOutcome
            reviewRecommendedAdmitStatus
            originalComment {
                createdDate
            }
        }
    }
`;
export const getVisitCommentsState = gql`
    query VisitCommentsState($filter: VisitCommentsStateFilter!) {
        visitCommentsState(filter: $filter) {
            unreadCount
        }
    }
`;

export const getVisitForPatientCard = gql`
    query VisitForPatientCard($id: Int!) {
        visit(id: $id) {
            id
            encounterNo
            attending {
                firstName
                id
                lastName
            }
            authNumber
            chiefComplaint
            latestCaseNote
            umNurseOwnerId
            umNurseOwner {
                firstName
                lastName
            }
            physicianAdvisorOwnerId
            physicianAdvisorOwner {
                firstName
                lastName
            }
            patient {
                firstName
                lastName
                mrn
            }
            secondaryPayer {
                code
                name
            }
            hospitalService {
                code
                name
            }
            admitStatusMetrics {
                daysInInpatient
                hoursInObservation
            }
        }
    }
`;

export const getCaregivers = gql`
    query Caregivers($type: String!) {
        caregivers(type: $type) {
            firstName
            id
            lastName
        }
    }
`;

export const getVisitsBySearchText = gql`
    query VisitsBySearchText($searchText: String!) {
        visitsBySearchText(searchText: $searchText) {
            id
            encounterNo
            admitDate
            dischargeDate
            admitStatus

            patient {
                mrn
                firstName
                middleName
                lastName
            }
        }
    }
`;

export const getCurrentAdmitStatusPrediction = gql`
    query VisitCurrentAdmitStatusPrediction($id: Int!) {
        visit(id: $id) {
            currentAdmitStatusPrediction {
                dischargeConfidence
                inpatientConfidence
                observationConfidence
            }
        }
    }
`;

export const getVisitsByWorklistTab = gql`
    query VisitsByWorklistTab($worklistTabName: String!, $filters: VisitFiltersInput!, $offset: Int!, $limit: Int!) {
        visitsByWorklistTab(worklistTabName: $worklistTabName, filters: $filters, offset: $offset, limit: $limit) {
            id
            encounterNo
            admitDate
            chiefComplaint
            los
            predictedLos
            priorityScore
            admitStatus
            predictedAdmitStatus
            predictedAdmitStatusConfidence
            dischargeDate
            authStatus
            authNumber
            lastReviewDate
            lastFaxRequestFailed
            latestCaseNote
            nextReviewDate
            umNurseOwnerId
            lastClinicalsSentDate

            umNurseOwner {
                firstName
                lastName
            }
            physicianAdvisorOwnerId
            physicianAdvisorOwner {
                firstName
                lastName
            }
            patient {
                firstName
                middleName
                lastName
                gender
                age
                mrn
                dob
            }
            location {
                facility {
                    name
                }
                poc {
                    name
                }
                bed
                room
            }
            hospitalService {
                name
            }
            primaryPayer {
                name
            }
            secondaryPayer {
                name
            }
            attending {
                firstName
                lastName
            }
            lastReviewOutcome
            lastReviewType
            lastReviewer {
                firstName
                lastName
            }
            lastSecondLevelReview {
                recommendedAdmitStatus
            }
        }
    }
`;

export const getHelpLinks = gql`
    query HelpLinks {
        helpLinks {
            url
            displayText
        }
    }
`;

export const getVisitSummary = gql`
    query VisitSummary($id: Int!) {
        visit(id: $id) {
            summaries {
                type

                items {
                    id
                    name
                    description
                    type
                    referenceId
                    descriptionJson
                }
            }
        }
    }
`;

export const getTextGenServiceEndpoint = gql`
    query Config {
        config {
            textGenService {
                endpoint
            }
        }
    }
`;

export const getImportMapUrl = gql`
    query Config {
        config {
            importMap {
                url
            }
        }
    }
`;
