export enum ClinicalSummaryStatusType {
    DRAFT = 'DRAFT',
    SENT = 'SENT',
}

export enum ClinicalSummaryItemType {
    LAB_RESULT = 'lab_result',
    MEDICATION_ADMINISTRATION = 'medication_administration',
    MEDICATION_ORDER = 'medication_order',
    DOCUMENT_QUOTE = 'document_quote',
    DOCUMENT = 'document',
    MICROBIOLOGY = 'microbiology',
}

export interface DocumentQuoteInput {
    documentId: number;
    quote: string;
    startRange: number;
    endRange: number;
}

export interface MutateClinicalSummaryItem {
    documentId?: number;
    medicationAdministrationId?: number;
    medicationOrderId?: number;
    microbiologyResultId?: number;
    labVitalsResultIds?: [number];
    documentQuote?: DocumentQuoteInput;
}

export interface AddToClinicalSummaryInput {
    documentQuote?: DocumentQuoteInput;
    type: ClinicalSummaryItemType;
    ids: string | string[];
}
