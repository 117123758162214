import { Role } from '@/auth/role.enum';
import { UserFunction } from '@/auth/userfunction.enum';
import { defineStore } from 'pinia';
import Vue from 'vue';

export const useUserStore = () => {
    const userStore = defineStore('user', {
        state: () => ({
            isInitialized: undefined as boolean | undefined,
            currentRole: undefined as Role | undefined,
            availableRoles: undefined as Role[] | undefined,
            isAdmin: false,
            isManager: false,
            canViewAnalytics: false,
            canBuildAnalytics: false,
            canViewTextGen: false,
        }),
        actions: {
            async initialize() {
                this.$patch({
                    isInitialized: false,
                });

                // Fetch the current access token from Okta. Note it's also cached in
                // localStorage after login but that can be modified so don't trust it.
                const response = await Vue.prototype.$auth.token.getWithoutPrompt();

                let roles: Role[] = [];
                const userFunctions: UserFunction[] = [];
                for (const iogroup of response.tokens.accessToken?.claims.iogroups) {
                    const [key, value] = iogroup.split(':');
                    if (key === 'io~role') {
                        const role = Role.fromJwtClaim(value);
                        if (role) {
                            roles.push(role);
                        }
                    }
                    if (key === 'io~func') {
                        const userFunction = UserFunction.fromJwtClaim(value);
                        if (userFunction) {
                            userFunctions.push(userFunction);
                        }
                    }
                }
                const canBuildAnalytics = userFunctions.includes(UserFunction.UM_ANALYTICS_BUILDER);
                const canViewAnalytics = canBuildAnalytics || userFunctions.includes(UserFunction.UM_ANALYTICS_VIEWER);
                const canViewTextGen = userFunctions.includes(UserFunction.TEXT_GENERATION);
                const isAdmin = roles.includes(Role.ADMIN);
                const isManager = roles.includes(Role.UM_MANAGER);
                if (isAdmin || isManager) {
                    roles = [Role.UM_NURSE, Role.PHYSICIAN_ADVISOR];
                }

                this.$patch({
                    isInitialized: true,
                    currentRole: roles[0],
                    availableRoles: roles,
                    isAdmin,
                    isManager,
                    canBuildAnalytics,
                    canViewAnalytics,
                    canViewTextGen,
                });
            },
            logout() {
                this.$reset();
            },
        },
    });
    const store = userStore();
    if (store.isInitialized === undefined) {
        store.initialize();
    }
    return store;
};
