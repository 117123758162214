
import { formatDateTime, formatPersonName } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import { ClinicalSummaryItemType } from '@/models';
import { Document } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DocumentDetailViewHeader',
    components: {
        AddToClinicalSummaryButton,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
    },
    props: {
        documentId: String,
        document: {
            type: Object as PropType<Document>,
            required: true,
        },
        compact: Boolean,
    },
    data: () => ({
        ClinicalSummaryItemType,
    }),
    methods: {
        formatDateTime,
        formatPersonName,
    },
});
