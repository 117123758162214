
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import { formatDate, properCase } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import LabsTable from '@/components/visit/labs/LabsTable.vue';
import { ToggleButtonName } from '@/models';
import { AddToClinicalSummaryInput, ClinicalSummaryItemType } from '@/models/visit/clinical-summary/ClinicalSummary.model';
import { getLabVitalsResultsTable } from '@/shared/queries';
import { LabVitalsResultsTable, Query, Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'LabsDrawerDetailView',
    components: { LabsTable, DrawerHeader, ToggleButtons, AddToClinicalSummaryButton },
    provide: {
        enableLabSelection: true,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            required: true,
        },
        categoryName: {
            type: String,
            required: true,
        },
        highlightEntityIds: {
            type: Set<string>,
            required: true,
        },
    },
    data: () => ({
        ToggleButtonName,
        selectedButton: undefined as ToggleButtonName | undefined,
        selectedVisit: undefined as Visit | undefined,
        currentVisitLabResults: undefined as LabVitalsResultsTable | undefined,
        previousVisitLabResults: undefined as LabVitalsResultsTable | undefined,
        selectedIds: [] as string[],
        clinicalSummaryItems: {} as AddToClinicalSummaryInput,
    }),
    computed: {
        hasPreviousVisit(): boolean {
            return !!this.visit.previousVisit;
        },
        visitDateRange(): string | undefined {
            if (this.selectedVisit) {
                return `${formatDate(this.selectedVisit.admitDate)} - ${formatDate(this.selectedVisit.dischargeDate ?? new Date())}`;
            } else {
                return undefined;
            }
        },
        labResults(): LabVitalsResultsTable | undefined {
            if (this.selectedButton === ToggleButtonName.CURRENT_STAY) {
                return this.currentVisitLabResults;
            } else if (this.selectedButton === ToggleButtonName.PREVIOUS_STAY) {
                return this.previousVisitLabResults;
            } else {
                return undefined;
            }
        },
    },
    created() {
        this.toggleView(ToggleButtonName.CURRENT_STAY);
    },
    methods: {
        properCase,
        async toggleView(selectedButton: ToggleButtonName): Promise<void> {
            this.selectedButton = selectedButton;

            if (selectedButton === ToggleButtonName.CURRENT_STAY && this.visit) {
                this.selectedVisit = this.visit;
                if (!this.currentVisitLabResults) {
                    this.currentVisitLabResults = await this.getLabsFromVisitSummary(this.selectedVisit.id);
                }
            } else if (selectedButton === ToggleButtonName.PREVIOUS_STAY && this.visit.previousVisit) {
                this.selectedVisit = this.visit.previousVisit;
                if (!this.previousVisitLabResults) {
                    this.previousVisitLabResults = await this.getLabsFromVisitSummary(this.selectedVisit.id);
                }
            }
        },
        async getLabsFromVisitSummary(visitId: string): Promise<LabVitalsResultsTable> {
            const response = await this.$apollo.query<Query>({
                query: getLabVitalsResultsTable,
                variables: {
                    visitId: parseInt(visitId),
                    category: this.categoryName,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.labVitalsResultsTable;
        },
        updateSelectedItems(ids: string[]) {
            this.selectedIds = [...ids];
            this.clinicalSummaryItems = { type: ClinicalSummaryItemType.LAB_RESULT, ids: this.selectedIds };
        },
    },
});
