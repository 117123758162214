export interface InitData {
    endpoint: string;
    visitId: number;
    token: string;
    divisionId: string;
    previousReview?: {
        reviewer: string;
        reviewDate: string;
    };
    showWarningDialog?: boolean;
    appName?: string;
    transferContent?: {
        enabled: boolean;
        label: string;
    };
}

export interface VisitSummaryServiceFunctionOverride {
    transferContent: (content: string) => Promise<void>;
    userAgreed: (accepted: boolean) => void;
}

export class VisitSummaryApp {
    static readonly visitSummaryDomain = 'UM';
    static readonly visitSummaryAppName = '@iodine/visit-summary';
    static readonly newSinceLastReviewAppName = '@iodine/visit-summary@1';
    static readonly visitSummaryAppContainerId = 'summaries';
    static readonly newSinceLastReviewAppContainerId = 'new-since-last-review';
    static readonly appId = 'single-spa-application:';
    static readonly newSinceLastReviewTypeCode = 'newSinceLastReview';
    static readonly chartSummaryTypeCode = 'chartSummary';
    static readonly conditionSummaryTypeCode = 'conditionSummary';
    static readonly singleSpaAppName = 'single-spa';
    static readonly transferContentLabel = 'Add to Clinical Summary';
    static singleSpa: any;
    static acceptedSharedDialog = true;

    static async init(initData: InitData, functions: VisitSummaryServiceFunctionOverride) {
        this.singleSpa = await this.importApp(this.singleSpaAppName);

        await [this.newSinceLastReviewAppName, this.visitSummaryAppName].reduce(async (acc, appName) => {
            await acc;
            let visitSummariesService;

            // check if visit summary app is already registered
            if (this.singleSpa.getAppStatus(appName) === null) {
                const app = await this.importApp(appName);
                visitSummariesService = app.visitSummaryService;
                // TODO: UM-1083
                visitSummariesService.userAgreed = functions.userAgreed;
                visitSummariesService.transferContent = (content: string) => functions.transferContent(content);
                this.singleSpa.registerApplication({
                    name: appName,
                    app,
                    activeWhen: ['/visit'],
                });
            }
            await this.singleSpa.start();

            //start generating summaries asynchronously
            try {
                await visitSummariesService.generateSummaries({
                    visitId: initData.visitId,
                    divisionId: initData.divisionId,
                    domain: this.visitSummaryDomain,
                    token: initData.token,
                    endpoint: initData.endpoint,
                    transferContent: initData.transferContent,
                    showWarningDialog: initData.showWarningDialog,
                    appName: appName,
                    ...this.addFields(appName, initData),
                });
            } catch (e) {
                console.log('Could not generate summaries for visit ' + initData.visitId);
            }
        }, Promise.resolve());
    }

    static addFields(appName: string, initData: InitData): Record<string, unknown> {
        switch (appName) {
            case this.visitSummaryAppName:
                return {
                    tabs: [this.chartSummaryTypeCode, this.conditionSummaryTypeCode],
                    defaultTab: this.chartSummaryTypeCode,
                };
            case this.newSinceLastReviewAppName:
                return {
                    tabs: [this.newSinceLastReviewTypeCode],
                    defaultTab: this.newSinceLastReviewTypeCode,
                    previousReview: {
                        reviewer: initData.previousReview?.reviewer,
                        reviewDate: initData.previousReview?.reviewDate,
                    },
                };
            default:
                return {};
        }
    }

    static async isAppRunning(importMapUrl: string): Promise<boolean> {
        if (!document.getElementById('import-map')) {
            //await this.setUpImportMap(importMapUrl);
        }
        this.singleSpa = await this.importApp(this.singleSpaAppName);

        return (
            this.singleSpa.getAppStatus(this.visitSummaryAppName) !== null ||
            this.singleSpa.getAppStatus(this.newSinceLastReviewAppName) !== null
        );
    }

    static async setUpImportMap(importMapUrl: string) {
        importMapUrl += `?cache_bust=${Date.now()}`;
        const importMapEl = document.createElement('script');
        importMapEl.id = 'import-map';
        importMapEl.type = 'systemjs-importmap';
        importMapEl.src = importMapUrl;
        document.head.append(importMapEl);
    }

    static async resetApp(appName: string) {
        await this.singleSpa.unregisterApplication(appName);
    }

    static async getVisitSummaryService(appName: string) {
        const visitSummaryApp = await this.importApp(appName);
        return visitSummaryApp.visitSummaryService;
    }

    static async hideApp(appName: string) {
        const appContainer = document.getElementById(this.appId + appName)!;
        const summariesWrapper: HTMLElement = document.getElementById('summaries-wrapper')!;
        if (appContainer) {
            summariesWrapper.appendChild(appContainer);
        }
        const service = await this.getVisitSummaryService(appName);
        await service.sharedDialogAccept(this.acceptedSharedDialog);
        await service.isActive(false);
    }

    static async mountApp(appName: string, wrapperId: string, appId: string) {
        let appContainer = document.getElementById(appId);
        if (!appContainer) {
            appContainer = document.createElement('div');
            appContainer.id = appId;
        }
        const wrapper: HTMLElement = document.getElementById(wrapperId)!;
        wrapper.appendChild(appContainer);
        const service = await this.getVisitSummaryService(appName);
        await service.sharedDialogAccept(this.acceptedSharedDialog);
        await service.isActive(true);
    }

    static async importApp(appName: string) {
        //@ts-ignore
        return await System.import(appName);
    }
}
