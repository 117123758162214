
import { ToggleButtonName } from '@/models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ToggleButtons',
    props: {
        buttons: Array<ToggleButtonName> as PropType<Array<ToggleButtonName>>,
        defaultButton: String as PropType<ToggleButtonName>,
        disabledMessage: String,
        disabledButtons: Array<ToggleButtonName> as PropType<Array<ToggleButtonName>>,
    },
    data: () => ({
        selectedButton: undefined as ToggleButtonName | undefined,
    }),
    watch: {
        defaultButton(button: ToggleButtonName) {
            this.selectedButton = button;
        },
    },
    created() {
        this.selectedButton = this.defaultButton;
    },
    methods: {
        toggleView(toggleButtonName: ToggleButtonName): void {
            this.selectedButton = toggleButtonName;
            this.$emit('switchedButtons', this.selectedButton);
        },
    },
});
