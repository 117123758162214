enum ToggleButtonName {
    MEDICATION_ADMINISTRATIONS = 'Administrations',
    MEDICATION_ORDERS = 'Orders',
    ABNORMAL_LABS = 'Abnormal Labs',
    ALL_LABS = 'All Labs',
    CURRENT_STAY = 'Current Stay',
    PREVIOUS_STAY = 'Previous Stay',
}

export default ToggleButtonName;
