
import Loadable from '@/components/shared/Loadable.vue';
import { formatDate, formatDateTime, formatTime, withDefault } from '@/components/shared/utils';
import VisitTimelineDocumentDetailView from '@/components/visit/timeline/VisitTimelineDocumentDetailView.vue';
import VisitTimelineFilters from '@/components/visit/timeline/VisitTimelineFilters.vue';
import VisitTimelineLabVitalsDetailView from '@/components/visit/timeline/VisitTimelineLabVitalsDetailView.vue';
import VisitTimelineMedicationsDetailView from '@/components/visit/timeline/VisitTimelineMedicationsDetailView.vue';
import VisitTimelineMicrobiologyDetailView from '@/components/visit/timeline/VisitTimelineMicrobiologyDetailView.vue';
import VisitTimelineSearchField from '@/components/visit/timeline/VisitTimelineSearchField.vue';
import { VisitTimelineEventDetails } from '@/models';
import { getTimelineEvents } from '@/shared/queries';
import { useVisitTimelineFiltersStore } from '@/stores/VisitTimelineFiltersStore';
import { useVisitTimelineStore } from '@/stores/VisitTimelineStore';
import { Query, VisitTimelineEvent } from 'generated/graphql/graphql';
import moment from 'moment';
import { mapStores } from 'pinia';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitTimeline',
    components: {
        VisitTimelineSearchField,
        VisitTimelineFilters,
        VisitTimelineDocumentDetailView,
        VisitTimelineLabVitalsDetailView,
        VisitTimelineMedicationsDetailView,
        VisitTimelineMicrobiologyDetailView,
        Loadable,
    },
    props: {
        admitDate: String,
    },
    data: () => ({
        events: [] as VisitTimelineEventDetails[],
        filteredEvents: [] as VisitTimelineEventDetails[],
        selectedEvent: undefined as VisitTimelineEventDetails | undefined,
        searchResultDocumentIds: new Set<string>(),
        DAY_EVENT_TYPE: 'DAY',
        currentDayIndicator: undefined,
        isLoadingSearchResults: false,
        firstSearchResult: '',
        searchText: '',
        searchBarExpanded: false,
        showFilters: false,
        filterStore: useVisitTimelineFiltersStore(),
        loading: true,
    }),
    computed: {
        ...mapStores(useVisitTimelineStore),
        ...mapStores(useVisitTimelineFiltersStore),
        isDetailViewOpen(): boolean {
            return this.selectedEvent !== undefined;
        },
    },
    watch: {
        selectedEvent(event: any) {
            if (event) {
                this.$nextTick(() => {
                    this.scrollTimelineTo(event.key);
                });
            }
        },
        events() {
            this.filterEvents();
        },
    },
    async created() {
        this.visitTimelineStore.reset();
        this.visitTimelineStore.$subscribe(() => {
            this.loadEvent(this.visitTimelineStore.selectedKey);
        });
        this.visitTimelineFiltersStore.$reset();
        this.visitTimelineFiltersStore.$subscribe(() => {
            this.filterEvents();
        });
        await this.loadTimeline(parseInt(this.$route.params.id));
        this.loading = false;
    },
    methods: {
        sortTimeline(): void {
            this.events = this.parseEvents(this.events.filter((e) => e.type !== this.DAY_EVENT_TYPE).reverse());
        },
        toggleSearchBarExpansion(): void {
            this.searchBarExpanded = !this.searchBarExpanded;
        },
        filterEvents(): void {
            this.filteredEvents = this.events.filter((event): boolean => !this.filterStore.filters[event.type]?.includes(event.header));
        },
        getDayIndicator(timestamp: string): string {
            const days = moment(timestamp).startOf('day').diff(moment(this.admitDate).startOf('day'), 'days');
            return `${days === 0 ? 'Admitted' : `Day ${days}`} - ${formatDate(timestamp)}`;
        },
        onIntersect(entries: any): void {
            if (entries[0].boundingClientRect.top <= 550) {
                this.currentDayIndicator = entries[0].target.dataset.day;
            }
        },
        loadingSearchResults(isLoading: boolean): void {
            this.isLoadingSearchResults = isLoading;
            this.firstSearchResult = '';
        },
        updateSearchText(searchText: string): void {
            this.searchText = searchText;
        },
        showSearchResults(searchResultDocumentIds: Set<string>): void {
            this.searchResultDocumentIds = searchResultDocumentIds;
        },
        isInSearchResults(event: VisitTimelineEventDetails) {
            if (event.id && this.searchResultDocumentIds.has(event.id) && event.key.startsWith('DOCUMENTATION')) {
                if (this.firstSearchResult === '') {
                    this.firstSearchResult = event.key;
                    this.$nextTick(() => {
                        this.scrollTimelineTo(this.firstSearchResult);
                    });
                }
                return true;
            }
        },
        async loadTimeline(visitId: number) {
            const response = await this.$apollo.query<Query>({
                query: getTimelineEvents,
                variables: {
                    visitId: visitId,
                },
                fetchPolicy: 'no-cache',
            });
            let result = response.data.visitTimelineEvents.items;
            this.events = this.parseEvents(result);
        },
        async loadEvent(key: string | undefined): Promise<void> {
            if (key) {
                const event = this.events.find((event) => key === event.key);
                if (!event) throw new TypeError('Timeline event not found: ' + key);
                this.selectedEvent = { ...event };
            } else {
                this.selectedEvent = undefined;
            }
        },
        openItem(event: VisitTimelineEventDetails): void {
            this.visitTimelineStore.openItem(event.key);
        },
        getCardIcon(type: string): string | undefined {
            if (['DOCUMENTATION', 'REPORTS', 'GENERAL_ORDER'].includes(type)) {
                return 'memo';
            } else if (['LABS_AND_VITALS', 'MICROBIOLOGY'].includes(type)) {
                return 'flask-vial';
            } else if (type === 'MEDICATION_ORDERS') {
                return 'pills';
            }
        },
        supportsDetailView(type: string): boolean {
            return ['DOCUMENTATION', 'REPORTS', 'GENERAL_ORDER', 'LABS_AND_VITALS', 'MICROBIOLOGY', 'MEDICATION_ORDERS'].includes(type);
        },
        parseEvents(events: Array<VisitTimelineEvent | VisitTimelineEventDetails>): VisitTimelineEventDetails[] {
            const result: VisitTimelineEventDetails[] = [];
            let lastTimestamp;

            const keyMap: { [k: string]: number } = {};

            for (const event of events) {
                // Generate key to ensure uniqueness in the timeline
                const type = ['REPORTS', 'GENERAL_ORDER'].includes(event.type) ? 'DOCUMENTATION' : event.type;
                let key = '';
                let icon: string | undefined;

                // only do the work of generating unique keys if the key has not already been generated
                if (!('key' in event)) {
                    key = [type, event.id, event.groupedByType, event.groupedByDate?.substring(0, 10)].filter(Boolean).join(':');

                    if (key in keyMap) {
                        // not unique
                        keyMap[key] += 1;
                        key = `${key}:${keyMap[key]}`;
                    } else {
                        // unique
                        keyMap[key] = 0;
                    }
                }

                // Add the icons
                if (!('icon' in event)) icon = this.getCardIcon(event.type);

                // Add the new DAY events
                if (lastTimestamp !== formatDate(event.timestamp)) {
                    result.push({
                        key: this.DAY_EVENT_TYPE + ':' + event.timestamp,
                        timestamp: event.timestamp,
                        type: this.DAY_EVENT_TYPE,
                        header: '',
                    });
                    lastTimestamp = formatDate(event.timestamp);
                }
                result.push({
                    key,
                    icon,
                    ...event, // spread event last so that previously generated keys/icons persist
                });
            }
            return result;
        },
        scrollTimelineTo(key: string) {
            const target = this.$refs['item-' + key];
            if (Array.isArray(target)) {
                this.$vuetify.goTo(target[0] as HTMLElement, { container: this.$refs.timeline as HTMLElement });
            }
        },
        formatDateTime,
        formatTime,
        formatDate,
        withDefault,
    },
});
