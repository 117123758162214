
import { EventBus, Events } from '@/components/shared/event-bus/EventBus';
import { ClinicalSummaryItemType, MutateClinicalSummaryItem } from '@/models';
import { AddToClinicalSummaryInput } from '@/models/visit/clinical-summary/ClinicalSummary.model';
import { addClinicalSummaryItem } from '@/shared/mutations';
import { getClinicalSummaryDraft } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation, Query } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'AddToClinicalSummaryButton',
    props: {
        text: Boolean,
        large: Boolean,
        itemToAdd: {
            type: Object as PropType<AddToClinicalSummaryInput>,
            required: true,
        },
    },
    data: () => ({
        visitStore: useVisitStore(),
    }),
    methods: {
        async addItem(item: AddToClinicalSummaryInput) {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalSummaryDraft,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.clinicalSummary = response.data.clinicalSummaryDraft;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: addClinicalSummaryItem,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        clinicalSummaryId: +this.visitStore.clinicalSummary.id,
                        ...this.addItemType(item.type),
                    },
                },
            });
            this.$toast.success('Added to Clinical Summary');
            EventBus.$emit(Events.CLINICAL_SUMMARY_SUBMISSION);
        },
        addItemType(type: ClinicalSummaryItemType): MutateClinicalSummaryItem {
            const ids = Array.isArray(this.itemToAdd.ids) ? this.itemToAdd.ids.map((id) => +id) : +this.itemToAdd.ids;
            switch (type) {
                case ClinicalSummaryItemType.DOCUMENT:
                    return { documentId: ids } as MutateClinicalSummaryItem;
                case ClinicalSummaryItemType.MEDICATION_ADMINISTRATION:
                    return { medicationAdministrationId: ids } as MutateClinicalSummaryItem;
                case ClinicalSummaryItemType.MEDICATION_ORDER:
                    return { medicationOrderId: ids } as MutateClinicalSummaryItem;
                case ClinicalSummaryItemType.MICROBIOLOGY:
                    return { microbiologyResultId: ids } as MutateClinicalSummaryItem;
                case ClinicalSummaryItemType.LAB_RESULT:
                    return {
                        labVitalsResultIds: ids,
                    } as MutateClinicalSummaryItem;
                case ClinicalSummaryItemType.DOCUMENT_QUOTE:
                    return {
                        documentQuote: { ...this.itemToAdd.documentQuote, documentId: ids },
                    } as MutateClinicalSummaryItem;
            }
        },
    },
});
