
import { Role } from '@/auth/role.enum';
import ClinicalsDueIcon from '@/components/shared/ClinicalsDueIcon.vue';
import ConfidenceIcon from '@/components/shared/ConfidenceIcon.vue';
import DischargeIcon from '@/components/shared/DischargeIcon.vue';
import NextReviewIcon from '@/components/shared/NextReviewIcon.vue';
import NoPredictionIcon from '@/components/shared/NoPredictionIcon.vue';
import ScoreDots from '@/components/shared/ScoreDots.vue';
import {
    abbreviateAdmitStatus,
    formatDate,
    formatDateTime,
    formatFacility,
    formatLocation,
    formatPersonName,
    formatTime,
    nullishCoalesce,
    optionalChain,
    withDefault,
} from '@/components/shared/utils';
import VisitOwnerSelector from '@/components/shared/VisitOwnerSelector.vue';
import FailedFaxIcon from '@/components/worklist/FailedFaxIcon.vue';
import WorklistReadmissionButton from '@/components/worklist/WorklistReadmissionButton.vue';
import { ADULT_AGE } from '@/models/visit/visit-summary/VisitPatientAge.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { Visit } from 'generated/graphql/graphql';
import moment from 'moment';
import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';

export default Vue.extend({
    name: 'WorklistItem',
    components: {
        ConfidenceIcon,
        ScoreDots,
        VisitOwnerSelector,
        WorklistReadmissionButton,
        VClamp,
        DischargeIcon,
        ClinicalsDueIcon,
        FailedFaxIcon,
        NextReviewIcon,
        NoPredictionIcon,
    },
    props: {
        visit: Object as PropType<Visit>,
        clinicalsDue: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        expanded: false,
        userStore: useUserStore(),
        umNurseType: Role.UM_NURSE,
        physicianAdvisorType: Role.PHYSICIAN_ADVISOR,
        featureStore: useFeatureStore(),
        ADULT_AGE,
    }),
    computed: {
        getReviewer(): string {
            const formattedReviewer = formatPersonName(this.visit.lastReviewer);
            let reviewer = '';
            if (formattedReviewer !== withDefault('')) {
                reviewer = formattedReviewer + ', ';
            }
            return reviewer;
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        showCompletedIcon(): boolean {
            return (
                !!this.visit.lastReviewDate && this.visit.lastReviewOutcome === 'COMPLETE' && this.visit.lastReviewType === 'FIRST_LEVEL'
            );
        },
        showDoctorIcon(): boolean {
            return (
                !!this.visit.lastReviewDate && this.visit.lastReviewOutcome === 'COMPLETE' && this.visit.lastReviewType === 'SECOND_LEVEL'
            );
        },
        showEscalatedIcon(): boolean {
            return !!this.visit.lastReviewDate && this.visit.lastReviewOutcome === 'ESCALATE';
        },
        caseButtonText(): string {
            return this.visit.lastReviewOutcome === 'COMPLETE' ? 'VIEW CASE' : 'REVIEW CASE';
        },
        showClinicalsDue(): boolean {
            return this.visit && this.clinicalsDue;
        },
        getStatusTooltipText(): string {
            if (this.showCompletedIcon || this.showDoctorIcon) {
                return 'Case Reviewed';
            }
            if (this.showEscalatedIcon) {
                return 'Case Escalated';
            }
            return '';
        },
        showConfidenceIcon(): boolean {
            if (this.featureStore.isEnabled('ADMIT_STATUS_MODEL_VERSION_4') && this.visit.admitStatus === this.visit.predictedAdmitStatus) {
                return false;
            }
            return true;
        },
        showNextReviewTodayBadge(): boolean {
            return this.featureStore.isEnabled('NEXT_REVIEW_DATE') && moment().isSameOrBefore(moment(this.visit.nextReviewDate), 'day');
        },
        showNoPredictionIcon(): boolean {
            return this.visit.predictedAdmitStatus == null;
        },
    },
    methods: {
        withDefault,
        formatPersonName,
        formatDate,
        formatDateTime,
        formatTime,
        formatFacility,
        formatLocation,
        nullishCoalesce,
        optionalChain,
        abbreviateAdmitStatus,
    },
});
